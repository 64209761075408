import { Redirect, Route } from 'react-router';
import useAuth from '../hooks/useAuth';
import { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { useLocation } from 'react-router-dom';

export interface IProps {
  exact?: boolean;
  path: string;
  component?: React.ComponentType<any>;
  restricted?: boolean;
};

const PublicRoute: React.FC<IProps> = ({ children, restricted, ...rest }) => {
  const location = useLocation();

  useEffect(() => {
    sendGAView();
  }, []);

  useEffect(() => {
    sendGAView();
  }, [location]);

  const sendGAView = () => {
    ReactGA.send({
      hitType: 'pageview',
      page: location.pathname,
      title: location.pathname,
    });
  }

  return (
    <Route
      {...rest}
      render={() => (children)}
    />
  )
};

export default PublicRoute;