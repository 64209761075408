import { createGlobalState } from 'react-hooks-global-state';
import { useDebounce } from 'use-debounce/lib';

type State = {
  search: string;
};

const initialState: State = { 
  search: ''
};
const { useGlobalState } = createGlobalState(initialState);

export default function useGlobalSearch() {
  const [search, setSearch] = useGlobalState('search');
  const [searchValue] = useDebounce(search, 500);

  return {
    globalKeyword: searchValue,
    setGlobalKeyword: setSearch,
  }
}