import { ChevronRight } from 'react-feather';
import { IntroBanner } from '../components';
import { prototypeLinks } from '../helpers/constant';
import AppLayout from '../layout/AppLayout';
import { CopyOutlined, SearchOutlined } from '@ant-design/icons';
import { Input, message } from 'antd';
import { useDebounce } from 'use-debounce';
import { useEffect, useMemo, useState } from 'react';

const ProjectPrototypeScreen = () => {
  async function copyLink(link: string) {
    try {
      await navigator.clipboard.writeText(link);
      message.info(`Success copy link ${link}`);
    } catch (error) {
      message.error(`Failed copy link`);
      console.error('failed copy link', error);
    }
  }

  const [search, setSearch] = useState('');

  const [value] = useDebounce(search, 500);

  const data = useMemo(() => {
    if (value) {
      return prototypeLinks.filter((item) =>
        item.title.toLocaleLowerCase().includes(value.toLocaleLowerCase())
      );
    }
    return prototypeLinks;
  }, [value]);

  function truncateLink(link: string) {
    const maxLength = 80;
    if (link.length > maxLength) {
      return link.slice(0, maxLength) + '...';
    }

    return link;
  }

  return (
    <AppLayout withoutContainer classLayout='projects'>
      <IntroBanner
        title='Project Prototypes'
        desc='List of all of projects prototype that already developed'
        classCustom='intro-project'
      />

      <div className='prototype-container'>
        <Input
          style={{ width: '100%' }}
          prefix={<SearchOutlined />}
          value={search}
          size='large'
          allowClear
          placeholder='Search by prototype name'
          onChange={(e) => setSearch(e.target.value)}
        />
      </div>
      <div className='project-prototype'>
        {data.map((item, key) => (
          <div key={key} className='project-prototype-item'>
            {/* <div className='preview'>
              {key % 2 === 0 ? (
                <embed
                  type='text/html'
                  // sandbox='allow-scripts allow-same-origin'
                  src={item.link}
                  width='100%'
                  height='100%'
                />
              ) : (
                <iframe src={item.link} width='100%' height='100%' />
              )}
            </div> */}
            <div>
              <h4>{item.title}</h4>
              <div className='content'>
                <div>
                  <div className='label'>Link</div>
                  <a href={item.link} target='_blank'>
                    Open Prototype Link
                  </a>
                </div>

                <button
                  className='btn-copy'
                  onClick={() => copyLink(item.link)}
                >
                  <CopyOutlined color='primary' />
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>
    </AppLayout>
  );
};

export default ProjectPrototypeScreen;
