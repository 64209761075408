import { useEffect, useState } from "react";
import AppLayout from "../layout/AppLayout";
import {
  FetchAllPlatformTagPropsResponse,
  PlatformTagProps,
} from "../types/platformTag.type";
import { generateQueryString } from "../helpers/generateQueryString";
import { httpRequest } from "../helpers/api";
import { initialProject, ProjectProps } from "../types/project.type";
import useFetchList from "../hooks/useFetchList";
import { WeLove } from "../components/Home";
import useWindowSize from "../hooks/useWindowSize";
import {
  IntroBanner,
  PlatformTagFilter,
  ProjectList,
  ProjectDetailModal,
} from "../components";
import { useParams } from "react-router-dom";

interface URLProps {
  projectId: string;
}

export default function ProjectScreen() {
  const { projectId } = useParams<URLProps>();
  const [platformTagList, setPlatformTagList] = useState<
    Array<PlatformTagProps>
  >([]);
  const [chosenPlatformTagId, setChosenPlatformTagId] = useState<String>("");
  const [selectedProject, setSelectedProject] =
    useState<ProjectProps>(initialProject);
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);

  const {
    isLoading,
    data: projectData,
    pagination,
    resetQueryAndResetData,
    changePage,
  } = useFetchList<ProjectProps>({
    endpoint: "projects",
    limit: 9,
    initialQuery: {
      filterStatus: "active",
    },
    pushData: true,
  });

  const { isMobile } = useWindowSize();

  useEffect(() => {
    fetchAllPlatformTag();
  }, []);

  useEffect(() => {
    resetQueryAndResetData({
      filterStatus: "active",
      includeTags: chosenPlatformTagId,
    });
  }, [chosenPlatformTagId]);

  useEffect(() => {
    if (projectId) {
      (async () => {
        const res = await httpRequest.get("/projects/" + projectId);
        setSelectedProject((res.data as any).payload as ProjectProps);
        setIsModalVisible(true);
      })();
    }
  }, [projectId, projectData]);

  const fetchAllPlatformTag = async () => {
    const res = await httpRequest.get<FetchAllPlatformTagPropsResponse>(
      `project/platform-tags${generateQueryString({
        isPublished: "active",
      })}`
    );
    setPlatformTagList(res.data.payload.results);
  };

  return (
    <AppLayout withoutContainer classLayout="projects">
      <IntroBanner
        title="Project"
        desc="We develop products and projects according to the client's vision and mission with creative solutions."
        classCustom="intro-project"
      />
      <div className="project-layout-content">
        <PlatformTagFilter
          platformTagList={platformTagList}
          chosenPlatformTagId={chosenPlatformTagId}
          setChosenPlatformTagId={setChosenPlatformTagId}
        />
        <ProjectList
          chosenPlatformTagId={chosenPlatformTagId}
          platformTagList={platformTagList}
          isLoading={isLoading}
          projectData={projectData}
          pagination={pagination}
          isMobile={isMobile}
          setSelectedProject={setSelectedProject}
          setIsModalVisible={setIsModalVisible}
          changePage={changePage}
        />
      </div>
      <ProjectDetailModal
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        selectedProject={selectedProject}
        isMobile={isMobile}
      />
      <WeLove />
    </AppLayout>
  );
}
