import React from 'react'
import { User } from 'react-feather';
import useModalAuth from '../hooks/useModalAuth';
import useAuth from '../hooks/useAuth';
import UserAvatar from './UserAvatar';

export default function SignInOrProfile() {
  const {setIsShowModalLogin} = useModalAuth()
  const {isLoggedIn} = useAuth();

  if (isLoggedIn) {
    return (
      <UserAvatar />
    );
  } else {
    return (
      <button
        className="btn btn-outlined"
        onClick={() => setIsShowModalLogin(true)}
      >
        <User size={18} />
        <span>Sign In</span>
      </button>
    );
  }
}