export function calculateAddDayForFirstOrder() {
  let addDay = 1
  const now = new Date()
  if (now.getHours() > 12) {
    addDay = 2
  } else if (now.getHours() === 12) {
    if (now.getMinutes() > 0) {
      addDay = 2
    }
  }
  return addDay;
}