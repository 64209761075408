import React from "react";
import { dataValues } from "../../constants";
import { ItemValues } from "../";
export default function Values() {
  return (
    <div className="values-about site-layout-content">
      <div className="content">
        <h3>Meet Our Values</h3>
        <p>Alone we can do so little, together we can do so much</p>
        <div className="item-container">
          {dataValues?.map((item: any, index: number) => (
            <ItemValues key={index} item={item} index={index} />
          ))}
        </div>
      </div>
    </div>
  );
}
