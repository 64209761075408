import { useEffect, useState } from "react";
import AppLayout from "../layout/AppLayout";
import { generateQueryString } from "../helpers/generateQueryString";
import { httpRequest } from "../helpers/api";
import useFetchList from "../hooks/useFetchList";
import {
  CareerTagProps,
  CareerTagWithCareerProps,
  FetchAllCareerTagResponse,
} from "../types/careerTag.type";
import { CareerProps, initialCareer } from "../types/career.type";
import {
  IntroBanner,
  Interested,
  CareerList,
  CareerDetailModal,
} from "../components";

export default function CareerScreen() {
  const [careerTagList, setCareerTagList] = useState<Array<CareerTagProps>>([]);
  const [finalCareerData, setFinalCareerData] = useState<
    Array<CareerTagWithCareerProps>
  >([]);
  const [selectedCareer, setSelectedCareer] =
    useState<CareerProps>(initialCareer);
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);

  const {
    isLoading,
    data,
    // pagination,
    // setData,
    // setSearch,
    // setQuery,
    // changePage,
  } = useFetchList<CareerProps>({
    endpoint: "careers",
    initialQuery: {
      isPublished: "active",
    },
    // pushData: true
  });

  useEffect(() => {
    fetchAllCareerTag();
    console.log("HELLO THERE");
  }, []);

  useEffect(() => {
    if (careerTagList && careerTagList.length > 0 && data && data.length > 0) {
      const newData = careerTagList.map((tag) => {
        return {
          ...tag,
          careers: data.filter((career) =>
            career.category?.find(
              (item) => item.careerTagId === tag.careerTagId
            )
          ),
        };
      });
      setFinalCareerData(newData);
    }
  }, [careerTagList, data]);

  const fetchAllCareerTag = async () => {
    const res = await httpRequest.get<FetchAllCareerTagResponse>(
      `career/tags${generateQueryString({
        isPublished: "active",
      })}`
    );

    setCareerTagList(res.data.payload.results);
  };

  const handleClickCareer = (career: CareerProps) => {
    setSelectedCareer(career);
    setIsModalVisible(true);
  };

  return (
    <AppLayout withoutContainer classLayout="career">
      <IntroBanner
        title="Career"
        desc="We are dedicated to creating an equitable environment, free from bias and barrier, offering the same opportunities for contribution, mentorship and progression to everyone."
      />
      <CareerList
        isLoading={isLoading}
        data={finalCareerData}
        handleClickCareer={handleClickCareer}
      />
      <CareerDetailModal
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        selectedCareer={selectedCareer}
      />
      <Interested />
    </AppLayout>
  );
}
