import { RightOutlined } from "@ant-design/icons";

interface IProps {
  item?: any;
  index?: number;
  handleClickCareer?: any;
}
export default function CareerItem({ item, index, handleClickCareer }: IProps) {
  return (
    <div className="career-item">
      <h3>{item?.careerTagName}</h3>
      {item?.careers?.map((subItem: any, subIndex: number) => (
        <p key={subIndex} onClick={() => handleClickCareer(subItem)}>
          {subItem?.positionName}
          <RightOutlined />
        </p>
      ))}
    </div>
  );
}
