import {
  imgServices1,
  imgClients1,
  imgClients2,
  imgClients3,
  imgClients4,
  imgClients5,
  imgClients6,
  imgClients7,
  imgClients8,
  imgClients9,
  imgClients10,
  imgProjects1,
  imgProjects2,
  imgProjects3,
  imgLogo,
  iconValues1,
  iconValues2,
  iconValues3,
  imgGoals1,
  imgGoals2,
  imgGoals3,
  imgTeams1,
  imgTeams2,
  imgTeams3,
} from '../assets/images';

export const dataAbout = {
  name: "PT. Vanz Inovatif Teknologi",
  about:
    "We build websites, mobile application, products and experience that represent you. Creative approaches to the client’s special needs in order to find unique and tailored IT Solution",
  address:
    "Ruko Sentra Niaga Green Lake L7 Jl. Green Lake City Boulevard Cengkareng, DKI Jakarta 11750",
  email: "contactus@qbit.co.id",
  phone: "+62 815 8853 286",
  instagram_link: "#/",
  linkedin_link: "#/",
  logo: imgLogo,
};

export const dataServices = [
  {
    title: "Product Design",
    desc: `
        <p>
          Etiam erat velit scelerisque in dictum non consectetur a erat. Enim
          nulla aliquet porttitor lacus luctus accumsan tortor posuere. Ut sem
          nulla pharetra diam.
        </p>
        <ul>
          <li>IOS Development</li>
          <li>Android Development</li>
          <li>Web Development</li>
        </ul>
      `,
    img: imgServices1,
  },
  {
    title: "Software Development",
    desc: `
        <p>
          Etiam erat velit scelerisque in dictum non consectetur a erat. Enim
          nulla aliquet porttitor lacus luctus accumsan tortor posuere. Ut sem
          nulla pharetra diam.
        </p>
    `,
    img: imgServices1,
  },
  {
    title: "Blockchain",
    desc: `
    <p>
      Etiam erat velit scelerisque in dictum non consectetur a erat. Enim
      nulla aliquet porttitor lacus luctus accumsan tortor posuere. Ut sem
      nulla pharetra diam.
    </p>
    <ul>
      <li>IOS Development</li>
      <li>Android Development</li>
      <li>Web Development</li>
    </ul>
    `,
    img: imgServices1,
  },
  {
    title: "Internet of Things (IoT)",
    desc: `
      <p>
        Etiam erat velit scelerisque in dictum non consectetur a erat. Enim
        nulla aliquet porttitor lacus luctus accumsan tortor posuere. Ut sem
        nulla pharetra diam.
      </p>
      `,
    img: imgServices1,
  },
  {
    title: "Manage Services",
    desc: `<p>
    Etiam erat velit scelerisque in dictum non consectetur a erat. Enim
    nulla aliquet porttitor lacus luctus accumsan tortor posuere. Ut sem
    nulla pharetra diam.
  </p>
  <ul>
    <li>IOS Development</li>
    <li>Android Development</li>
    <li>Web Development</li>
  </ul>`,
    img: imgServices1,
  },
  {
    title: "Games",
    desc: `<p>
    Etiam erat velit scelerisque in dictum non consectetur a erat. Enim
    nulla aliquet porttitor lacus luctus accumsan tortor posuere. Ut sem
    nulla pharetra diam.
  </p>
  <ul>
    <li>IOS Development</li>
    <li>Android Development</li>
  </ul>`,
    img: imgServices1,
  },
];

export const dataClients = [
  imgClients1,
  imgClients2,
  imgClients3,
  imgClients4,
  imgClients5,
  imgClients6,
  imgClients7,
  imgClients8,
  imgClients9,
  imgClients10,
];

export const dataProjects = [
  {
    title: "INVES",
    desc: "Id venenatis a condimentum vitae sapien pellentesque habitant. Eget dolor morbi non arcu risus quis varius. Ultrices dui sapien eget mi proin sed libero enim.",
    img: imgProjects1,
    bgColor: "#333333",
    color: "#ffffff",
  },
  {
    title: "MI Kitchen",
    desc: "Id venenatis a condimentum vitae sapien pellentesque habitant. Eget dolor morbi non arcu risus quis varius. Ultrices dui sapien eget mi proin sed libero enim.",
    img: imgProjects2,
    bgColor: "#FEC200",
    color: "#000000",
  },
  {
    title: "Explorasa",
    desc: "Id venenatis a condimentum vitae sapien pellentesque habitant. Eget dolor morbi non arcu risus quis varius. Ultrices dui sapien eget mi proin sed libero enim.",
    img: imgProjects3,
    bgColor: "#8CD4B3",
    color: "#000000",
  },
];

export const dataValues = [
  {
    icon: iconValues1,
    desc: "We believe in treating our customers with respect and faith.",
  },
  {
    icon: iconValues2,
    desc: "We grow through creativity, invention and innovation.",
  },
  {
    icon: iconValues3,
    desc: "We integrate honesty, integrity and business ethics into all aspects of our IT and business functioning.",
  },
];

export const dataGoals = [
  {
    title: "Goals",
    img: imgGoals1,
    desc: "To be a leader in the IT (information technology) industry by providing IT solutions, services, relationship and mutual profitability.",
  },
  {
    title: "Vision",
    img: imgGoals2,
    desc: `<ul><li>Being a true industry leader in IT solution.</li><li>Provide quality solution and services that exceeds the expectations of our esteemed customers.</li></li><li>Creating application that has value and benefit in society, as well as profitability to the company.</li></ul>`,
  },
  {
    title: "Mission",
    img: imgGoals3,
    desc: "Build long term relationships with our customers and clients and provide exceptional IT solution and services by pursuing business through innovation and advanced technology.",
  },
];

export const dataTeams = [
  {
    id: 1,
    name: 'Ronald Gunawan',
    title: 'Chief Executive Officer',
    desc: '<p>Ronald is qbit’s CEO and Project Manager. He has been working for 18+ years in the IT (Mobile Application) Industry. During his career, Ronald established himself as a renowned Mobile Application and IT Consultant. In the past, Ronald has been working as an R&D mobile application developer, and then continuing his job as a Project Manager in several IT companies. He also has experienced to set a new IT mobile division for one international company, lead one joint venture company as deputy managing director, and the latest, he becomes CTO of one public listed companies.</p><p>Ronald graduated from the University of Pelita Harapan, Indonesia in 2004 with a degree in Computer Science.</p>',
    img: imgTeams1,
  },
  {
    name: 'Irvan Chandra',
    title: 'Chief Operating Officer',
    desc: '<p>Irvan is qbit’s COO. He had participated in an electrical car model project in Germany. During these period he is trained in technical as well as business marketing expertise. In July 2017 he founded the company together with Evan. Irvan graduated from the Leibniz University of Hannover, Germany in 2017 with a degree in Mechatronic.</p>',
    img: imgTeams2,
  },
  {
    name: 'Evan Leonardi',
    title: 'Chief Technology Officer',
    desc: '<p>Evan is qbit’s Technical Specialist. He has been working for 15+ years in the IT (Mobile Application) Industry. During his career, Evan established himself as an IT and R&D specialist. He has the ability to be a fast learner in learning something new.</p><p>In the past, Evan has been working as an R&D specialist in mobile application and server. And the latest, he is doing on researching for Blockchain, AI, ML and IOT. In July 2017 he founded the company together with Irvan.</p><p>Evan graduated from the University of Bina Nusantara, Indonesia in 2007 with a degree in Computer Science</p>',
    img: imgTeams3,
  },
];
