import { Row, Radio, Typography, RadioChangeEvent, Input, Col } from 'antd';
import CheckableTag from 'antd/lib/tag/CheckableTag';
import React from 'react'
import styled from 'styled-components';
import useGlobalConfig from '../hooks/useGlobalConfig';
import useWindowSize from '../hooks/useWindowSize';
import AppButton from './AppButton';
import { useDebounce } from 'use-debounce';

const { Title, Text } = Typography;

enum ESortBy {
  newest = 'newest',
  most_popular = 'most-popular',
}

export type IFilterMenu = {
  sortBy: ESortBy;
  minPrice: number;
  maxPrice: number;
  tags: string[];
  allergens: string[];
}

type Props = {
  needConfirm?: boolean;
  onChangeFilter: (newFilter: IFilterMenu) => void;
}

export default function FilterMenu(props: Props) {
  // const { tags, allergens } = useGlobalConfig()

  const [sortBy, setSortBy] = React.useState<ESortBy>(ESortBy.most_popular)
  const [minPrice, setMinPrice] = React.useState(0)
  const [maxPrice, setMaxPrice] = React.useState(0);
  const [selectedTags, setSelectedTags] = React.useState<string[]>([])
  const [selectedAllergens, setSelectedAllergens] = React.useState<string[]>([])

  const [valueMinPrice] = useDebounce(minPrice, 500);
  const [valueMaxPrice] = useDebounce(maxPrice, 500);


  React.useEffect(() => {
    if (!props.needConfirm) {
      props.onChangeFilter({
        sortBy,
        minPrice: valueMinPrice,
        maxPrice: valueMaxPrice,
        tags: selectedTags,
        allergens: selectedAllergens,
      })
    }
  }, [sortBy, valueMinPrice, valueMaxPrice, selectedTags, selectedAllergens])

  return (
    <>
      <Title level={3}>Filters</Title>
      <Row>
        <Text
          style={{
            fontSize: '16px',
            fontWeight: 600,
            letterSpacing: '-0.02em',
            textAlign: 'left',
          }}
        >
          Sort
        </Text>
      </Row>
      <Row>
        <Radio.Group onChange={(e) => setSortBy(e.target.value)} value={sortBy}>
          <Row style={{ marginBottom: 5 }}>
            <Radio value={ESortBy.most_popular}>
              <Text
                style={{
                  fontSize: '14px',
                  fontWeight: 400,
                  letterSpacing: '-0,02em',
                  textAlign: 'left',
                }}
              >
                Most Popular
              </Text>
            </Radio>
          </Row>
          <Row>
            <Radio value={ESortBy.newest}>
              <Text
                style={{
                  fontSize: '14px',
                  fontWeight: 400,
                  letterSpacing: '-0,02em',
                  textAlign: 'left',
                }}
              >
                Newest
              </Text>
            </Radio>
          </Row>
        </Radio.Group>
      </Row>

      {/**  price range filter */}
      <Row style={{ marginTop: 20 }}>
        <Text
          style={{
            fontSize: '16px',
            fontWeight: 600,
            letterSpacing: '-0.02em',
            textAlign: 'left',
          }}
        >
          Price Range
        </Text>
      </Row>

      <Row wrap gutter={16}>
        <Col xs={12} lg={24}>
          <Text
            style={{
              fontSize: '12px',
            }}
          >
            Min Price
          </Text>

          <InputPrice
            placeholder="Enter Price"
            type={'number'}
            prefix={'$'}
            onChange={(event) => setMinPrice(Number(event.target.value))}
            style={{ marginBottom: 10 }}
          />
        </Col>
        <Col xs={12} lg={24}>
          <Text
            style={{
              fontSize: '12px',
            }}
          >
            Max Price
          </Text>

          <InputPrice
            placeholder="Enter Price"
            type={'number'}
            prefix={'$'}
            onChange={(event) => setMaxPrice(Number(event.target.value))}
            style={{ marginBottom: 20 }}
          />
        </Col>
      </Row>

      {/**Tags Filter */}
      {/* <Row>
        <Text
          style={{
            fontSize: '16px',
            fontWeight: 600,
            letterSpacing: '-0.02em',
            textAlign: 'left',
          }}
        >
          Tags
        </Text>
      </Row>
      <Row wrap={true} gutter={[8, 5]} style={{ marginBottom: 20 }}>
        {tags.map((tag) => {
          return (
            <CheckableTag
              style={{
                padding: '5px 13px 5px 13px',
                borderRadius: 20,
                backgroundColor: selectedTags.includes(tag.tagId as string)
                  ? '#0B998E'
                  : '#F1F3F5',
              }}
              key={tag.tagId}
              onChange={() => {
                if (selectedTags.includes(tag.tagId as string)) {
                  setSelectedTags([
                    ...selectedTags.filter((item) => item !== tag.tagId),
                  ]);
                } else {
                  setSelectedTags([...selectedTags, tag.tagId as string]);
                }
              }}
              checked={selectedTags.includes(tag.tagId as string)}
            >
              {tag.name}
            </CheckableTag>
          );
        })}
      </Row> */}

      {/**Allergens Filter */}
      {/* <Row>
        <Text
          style={{
            fontSize: '16px',
            fontWeight: 600,
            letterSpacing: '-0.02em',
            textAlign: 'left',
          }}
        >
          Allergens
        </Text>
      </Row>
      <Row wrap={true} gutter={[8, 5]}>
        {allergens.map((allergen) => {
          return (
            <CheckableTag
              style={{
                padding: '5px 13px 5px 13px',
                borderRadius: 20,
                backgroundColor: selectedAllergens.includes(
                  allergen.tagId as string
                )
                  ? '#0B998E'
                  : '#F1F3F5',
              }}
              key={allergen.tagId}
              onChange={() => {
                if (selectedAllergens.includes(allergen.tagId as string)) {
                  setSelectedAllergens([
                    ...selectedAllergens.filter(
                      (item) => item !== allergen.tagId
                    ),
                  ]);
                } else {
                  setSelectedAllergens([
                    ...selectedAllergens,
                    allergen.tagId as string,
                  ]);
                }
              }}
              checked={selectedAllergens.includes(allergen.tagId as string)}
            >
              {allergen.name}
            </CheckableTag>
          );
        })}
      </Row> */}

      {props.needConfirm && (
        <AppButton block type="primary" style={{ marginTop: 20 }}>
          Confirm Filter
        </AppButton>
      )}
    </>
  );
}

const InputPrice = styled(Input)`
  background-color: #f1f3f5;
  border-radius: 100px;
  color: #b7c2cc;
  .ant-input-prefix {
    padding: 0px 3px 0px 0px;
    color: #b7c2cc;
  }
  .ant-input {
    background-color: #f1f3f5;
  }
  // :not([value=""]) {
  //   background-color: #ffffff !important;
  //   border-radius: 100px;import useGlobalConfig from '../hooks/useGlobalConfig';
import useWindowSize from '../hooks/useWindowSize';

  //   color: #b7c2cc;

  //   .ant-input-prefix {
  //     padding: 0px 3px 0px 0px;
  //     color: #b7c2cc;
  //   }
  //   .ant-input {
  //     background-color: #ffffff;
  //   }
  // }
`;

