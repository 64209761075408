import useGlobalConfig from "../../hooks/useGlobalConfig";
export default function Interested() {
  const { aboutUs } = useGlobalConfig();
  return (
    <div className="we-love-bg-edge">
      <div className="interested we-love-home">
        <div className="we-love-container">
          {/* <span />
          <span /> */}
          <div className="content">
            <h3>Interested in being part of our team?</h3>
            <p>
              Send your CV and Portfolio to{" "}
              <a
                href={`mailto:${aboutUs?.email}`}
                target="_blank"
                rel="noreferrer"
              >
                {aboutUs?.email}
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
