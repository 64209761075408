import React, { useEffect } from "react";
import { Route, useHistory, useLocation } from "react-router-dom";
import useAuth from '../hooks/useAuth';
import ReactGA from 'react-ga4';

interface IProps {
  exact?: boolean
  path: string
}

const PrivateRoute: React.FC<IProps> = ({ children, ...rest }) => {
  const location = useLocation();

  const history = useHistory();
  const { isLoading, isLoggedIn } = useAuth();

  useEffect(() => {
    if (!isLoading && !isLoggedIn) {
      history.push('/');
    }
  }, [isLoading, isLoggedIn]);
  
  useEffect(() => {
    sendGAView();
  }, []);

  useEffect(() => {
    sendGAView();
  }, [location]);

  const sendGAView = () => {
    ReactGA.send({
      hitType: 'pageview',
      page: location.pathname,
      title: '(PRIVATE) ' + location.pathname,
    });
  };

  return (
    <Route
      {...rest}
      render={({ location, match }: any) => {
        // if (!isLoggedIn) {
        //   return (
        //     <Redirect
        //       to={{
        //         pathname: "/",
        //         state: { from: location }
        //       }}
        //     />
        //   )
        // } else {
          return children
        // }
      }}
    />
  )
}

export default PrivateRoute;
