import { useEffect } from 'react';
import { getToken, removeToken, saveToken } from '../helpers/auth';
import { createGlobalState } from 'react-hooks-global-state';
import { IUser, initialUser } from '../types/user.type';
import { httpRequest } from '../helpers/api';
import { IHttpResponse } from '../helpers/pagination';
import { message } from 'antd';
import { getErrorMessage } from '../helpers/errorHandler';

type IAuthData = {
  isLoading?: boolean,
  token: string;
  user: IUser;
};

const initialState: IAuthData = { 
  isLoading: true,
  token: '', 
  user: { ...initialUser }
};
const { useGlobalState } = createGlobalState(initialState);

export default function useAuth() {
  const [isLoading, setIsLoading] = useGlobalState('isLoading');
  const [token, setToken] = useGlobalState('token');
  const [user, setUser] = useGlobalState('user');

  useEffect(() => {
    init();
  }, [])
  
  const init = async () => {
    const tokenString = await getToken()
    if (tokenString) {
      setToken(tokenString)
    }
    setIsLoading(false)
  };

  const getProfile = () => {
    setIsLoading(true);
    httpRequest
      .get<IHttpResponse<IUser>>('/user/me', {
        headers: {
          Authorization: 'Bearer ' + token
        }
      })
      .then((resultUser) => {
        setUser(resultUser.data.payload);
        setIsLoading(false);
      })
      .catch((err) => {
        message.error(getErrorMessage(err));
        setIsLoading(false);
      });
  }

  const saveProfile = async (data: IUser) => {
    setIsLoading(true);
    // const formData = new FormData();
    // Object.keys(data).forEach((key) => {
    //   if ((data as any)[key]) {
    //     formData.append(key, (data as any)[key]);
    //   }
    // });

    console.log("NEW DATA")
    console.log(data)

    const formData = {
      name: data.name,
      email: data.email,
      phone: data.phone,
      userType: data.userType,
      status: data.status
    }

    const imageData = new FormData();
    if (data.image) imageData.append('image', data.image);

    try {
      const resultUpdate = await httpRequest.patch<IHttpResponse<IUser>>(
        '/user/' + user.userId,
        formData,
      );
      if (data.image) {
        await httpRequest.put(
          '/user/' + user.userId + '/photo', 
          imageData, 
          {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }
        )
      }
      console.log("THIS IS NEW USER DATA")
      console.log(resultUpdate.data.payload)
      setUser(resultUpdate.data.payload);
      setIsLoading(false);
      message.success('Update profile successfully')
    } catch(err) {
      message.error(getErrorMessage(err));
      setIsLoading(false);
    }
  }

  const login = (data: IAuthData) => {
    saveToken(data.token);
    setToken(data.token);

    setUser(data.user);
  };

  const logout = () => {
    removeToken();
    setToken('');
    setUser({...initialUser});

    // message.success('Logout successfully');
  };

  return {
    isLoading,
    isLoggedIn: token ? true : false,
    token,
    user,
    getProfile,
    login,
    logout,
    saveProfile,
  };
}