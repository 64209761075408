import React from "react";
import { Link, useLocation } from "react-router-dom";
import FAQItem from "../components/FAQItem";
import HeaderBannerItem from "../components/HeaderBannerItem";
import HeaderSection from "../components/HeaderSection";
import HowItWorkItem from "../components/HowItWorkItem";
import OurLocation from "../components/OurLocation";
import TopPick from "../components/TopPick";
import AppLayout from "../layout/AppLayout";
import { httpRequest } from "../helpers/api";
import { IHttpResponse, IPayloadPagination } from "../helpers/pagination";
import { generateQueryString } from "../helpers/generateQueryString";
import { Carousel, Modal } from "antd";
import { IMenu } from "../types/menu.type";
import ModalMenuDetail, { AddToCartItem } from "../components/ModalMenuDetail";
import useAuth from "../hooks/useAuth";
import useModalAuth from "../hooks/useModalAuth";
import useCart, { CartItem } from "../hooks/useCart";
import { useHistory } from "react-router-dom";
import moment from "moment";
import useGlobalConfig from "../hooks/useGlobalConfig";
import useGlobalFilter from "../hooks/useGlobalFilter";
import useWindowSize from "../hooks/useWindowSize";
import {
  MainBanner,
  Services,
  Projects,
  Clients,
  WeLove,
} from "../components/Home";

interface IBanner {
  bannerId: string;
  bannerImg: string;
  subtitle: string;
  title: string;
}

interface IHow {
  howitworksId: string;
  howitworksImg: string;
  queueOrder: string;
  headline: string;
  subtitle: string;
}

export interface IFAQs {
  answer: string;
  question: string;
  faqId: string;
}

const Homepage: React.FC = () => {
  const { isMobile } = useWindowSize();
  const history = useHistory();
  let refServices = React.useRef<any>();
  let refClients = React.useRef<any>();
  let refGetInTouch = React.useRef<any>();
  // let refOurLocations = React.useRef<any>();
  // let refCarouselBanner = React.useRef<any>();
  const { user, isLoggedIn } = useAuth();
  // const { setIsShowModalLogin } = useModalAuth();
  const { clearCart, addMultipleToCart } = useCart();
  // const {locations} = useGlobalConfig();

  // const { setIsShowModalSelectLocation, setSelectedLocation } =
  //   useGlobalFilter();

  const location = useLocation();

  // const [dataBanners, setDataBanners] = React.useState<IBanner[]>([]);
  // const [dataHows, setDataHows] = React.useState<IHow[]>([]);
  // const [dataFAQs, setFAQs] = React.useState<IFAQs[]>([]);
  // const [menusTopPick, setMenusTopPick] = React.useState<IMenu[]>([]);

  const [isShowModalMenuDetail, setIsShowModalMenuDetail] =
    React.useState(false);
  const [selectedMenu, setSelectedMenu] = React.useState<IMenu>();

  React.useEffect(() => {
    if (location.hash && location.hash.includes("#")) {
      const exp = location.hash.split("#");
      if (exp.length > 1 && exp[1]) {
        if (exp[1] === "services" && refServices && refServices.current) {
          refServices.current.scrollIntoView({ behavior: "smooth" });
        }
        if (exp[1] === "clients" && refClients && refClients.current) {
          refClients.current.scrollIntoView({ behavior: "smooth" });
        }
        if (exp[1] === "get-in-love" && refGetInTouch && refGetInTouch.current) {
          refGetInTouch.current.scrollIntoView({ behavior: "smooth" });
        }
        //  else if (
        //   exp[1] === "our-locations" &&
        //   refOurLocations &&
        //   refOurLocations.current
        // ) {
        //   refOurLocations.current.scrollIntoView({ behavior: "smooth" });
        // }
      }
    }
  }, [location]);

  // React.useEffect(() => {
    // const fetchBanners = async () => {
    //   const res = await httpRequest.get<IHttpResponse<IPayloadPagination<IBanner>>>('banners' + generateQueryString({ isActive: true }))
    //   if (res.data.payload.results.length > 0) {
    //     setDataBanners(res.data.payload.results);
    //   }
    // }
    // fetchBanners()
  // }, []);

  // React.useEffect(() => {
    // const fetchHow = async () => {
    //   const res = await httpRequest.get<IHttpResponse<IPayloadPagination<IHow>>>('howitworks')
    //   setDataHows(res.data.payload.results)
    // }
    // fetchHow()
  // }, []);

  // React.useEffect(() => {
    // const fetchFAQs = async () => {
    //   const res = await httpRequest.get<IHttpResponse<IPayloadPagination<IFAQs>>>('faqs' + generateQueryString({
    //     // limit: 5,
    //     isImportant: true
    //   }))
    //   setFAQs(res.data.payload.results)
    // }
    // fetchFAQs()
  // }, []);

  // React.useEffect(() => {
    // const fetchMenus = async () => {
    //   const res = await httpRequest.get<IHttpResponse<IPayloadPagination<IMenu>>>('menus' + generateQueryString({
    //     limit: 10,
    //     isTopPick: true,
    //   }))
    //   setMenusTopPick(res.data.payload.results)
    // }
    // fetchMenus()
  // }, []);

  const handleClickAddToCart = (newDataCart: AddToCartItem[]) => {
    if (selectedMenu) {
      const list = [];
      for (const item of newDataCart) {
        const addedItem: CartItem = {
          productId: selectedMenu.menuId,
          productVariantId: newDataCart[0].menuVariantId,
          quantity: item.qty,
          buyerId: user.userId,

          productDetail: {
            ...selectedMenu,
            variant: {} as any,
          },
          meta: {
            pickupFromLocationId: item.location?.locationId || "",
            pickupScheduledAt: moment(item.date).format("YYYY-MM-DD") || "",
            pickupTime: {
              startAt: item.availabilityPickupTime?.startAt,
              endAt: item.availabilityPickupTime?.endAt,
            },
          },
        };
        list.push(addedItem);
      }
      addMultipleToCart(list, "add", () => {
        history.push("/menu");
      });
    }
  };

  return (
    <AppLayout isHomepage withoutContainer>
      {/* <Carousel ref={refCarouselBanner} {...carouselSettings} dots={!isMobile}>
        {dataBanners.map((item) => (
          <HeaderBannerItem
            key={item.bannerId}
            title={item.title}
            subtitle={item.subtitle}
            rightImageUrls={item.bannerImg}
          />
        ))}
      </Carousel> */}

      <MainBanner />
      <Services ref={refServices} />
      <Projects />
      <Clients ref={refClients} />
      <WeLove ref={refGetInTouch}/>

      {/* <div className="homepage-section">
        <div className="bottom-banner-wrapper">
          <h1>Bringing Healthy Food to Your Everyday </h1>
          <Link to="menu" className="bottom-banner-link">
            <img alt="menu-icon" src="./images/tudung.svg" />
            <span>Order Now</span>
          </Link>
        </div>
      </div> */}
    </AppLayout>
  );
};

export default Homepage;

export const carouselSettings = {
  autoplay: true,
  slidesToShow: 1,
  infinite: true,
  speed: 500,
  rows: 1,
  slidesPerRow: 1,
  draggable: true,
  fade: true,
};
