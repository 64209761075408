import { useEffect, useMemo, useRef, useState } from "react";
import { Button, Modal, Image } from "antd";
import isDarkColor from "is-dark-color";

import {
  iconPlaystore,
  iconAppstore,
  iconWebsite,
  iconAndroid,
  iconIos,
  iconWeb,
} from "../../assets/images";
import HTMLPreview from "../HTMLPreview";
import { ProjectProps } from "../../types/project.type";

type Props = {
  isModalVisible: boolean;
  setIsModalVisible: (arg0: boolean) => any;
  selectedProject: ProjectProps;
  isMobile: boolean;
};

const iconMap = [
  {
    name: "Android",
    icon: iconAndroid,
  },
  {
    name: "IOS",
    icon: iconIos,
  },
  {
    name: "Web",
    icon: iconWeb,
  },
];

const iconKey = ["Android", "IOS", "Web"];

export default function ProjectDetailModal({
  isModalVisible,
  setIsModalVisible,
  selectedProject,
  isMobile,
}: Props) {

  const textColor = useMemo(
    () =>
      isDarkColor("#" + selectedProject?.backgroundColor)
        ? "#ffffff"
        : "#3a3a3a",
    [selectedProject]
  );

  const renderLinkButton = (text: string, icon: JSX.Element, link: string) => (
    <Button
      onClick={() => {
        const win = window.open(link);
        win?.focus();
      }}
    >
      <div>{text}</div>
      {icon}
    </Button>
  );

  return (
    <Modal
      className="modal-project-selected"
      visible={isModalVisible}
      onCancel={() => setIsModalVisible(false)}
      onOk={() => setIsModalVisible(false)}
      footer={null}
      width={isMobile ? "90%" : "600px"}
      bodyStyle={{ padding: 0 }}
    >
      <div
        className="content-container"
        style={{
          backgroundColor: "#" + selectedProject?.backgroundColor,
          //   display: "flex",
          //   flexDirection: "column",
          //   alignItems: "center",
          //   paddingBottom: isMobile ? "100px" : "150px",
          //   marginBottom: isMobile ? "100px" : "150px",
        }}
      >
        <h3
          className="title"
          style={{
            color: textColor,
            //   fontFamily: "Work Sans",
            //   fontStyle: "normal",
            //   fontWeight: 600,
            //   fontSize: "49px",
            //   lineHeight: "120%",
            //   marginBottom: "12px",
            //   textAlign: "center",
            //   zIndex: 10,
          }}
        >
          {selectedProject?.title}
        </h3>
        <h4
          className="subtitle"
          style={{
            color: textColor,
            //   fontFamily: "Work Sans",
            //   fontStyle: "normal",
            //   fontWeight: 600,
            //   fontSize: "20px",
            //   lineHeight: "150%",
            //   marginBottom: "50px",
            //   textAlign: "center",
          }}
        >
          {selectedProject?.subtitle}
        </h4>

        <div className="img-container">
          <img
            src={selectedProject?.coverImage?.imageUrl}
            alt={selectedProject?.title || "image-selected"}
          />
        </div>
      </div>

      <div className="detail-content">
        {(selectedProject?.playstoreLink ||
          selectedProject?.appstoreLink ||
          selectedProject?.webLink) && (
          <div className="available-btn">
            {/* {selectedProject?.playstoreLink &&
              renderLinkButton(
                "View in Playstore",
                <img src={iconPlaystore} alt="playstore" />,
                selectedProject?.playstoreLink
              )}
            {selectedProject?.appstoreLink &&
              renderLinkButton(
                "View in Appstore",
                <img src={iconAppstore} alt="appstore" />,
                selectedProject?.appstoreLink
              )}
            {selectedProject?.webLink &&
              renderLinkButton(
                "Visit Website",
                <img src={iconWebsite} alt="website" />,
                selectedProject?.webLink
              )} */}
            {selectedProject?.playstoreLink && (
              <img src="/images/get-it-on-playstore.png" alt="playstore" />
            )}
            {selectedProject?.appstoreLink && (
              <img src="/images/download-on-the-appstore.png" alt="playstore" />
            )}
            {selectedProject?.webLink && (
              <img src="/images/view-on-website.png" alt="playstore" />
            )}
          </div>
        )}

        <h4>About Project</h4>
        <HTMLPreview html={selectedProject?.description} />

        <h4>Platform</h4>
        <div className="platform-list">
          {selectedProject?.platformTags?.map((tag) => {
            const icon = iconMap?.find(
              (im) => im?.name === tag.platformTagName
            );

            return (
              <div className="item-platform">
                {icon && (
                  <img src={icon.icon} alt={`${tag?.platformTagName}`} />
                )}
                <p>{tag?.platformTagName}</p>
              </div>
            );
          })}
        </div>

        <h4>Gallery</h4>
        {selectedProject?.galleryImages?.map((img) => (
          <div className="img-gallery-container">
            <Image width={"100%"} src={img?.imageUrl} />
          </div>
        ))}
      </div>
    </Modal>
  );
}
