type Props = {
  title?: string;
  subtitle?: string;
};

export default function HeaderContent({ title, subtitle }: Props) {
  return (
    <div className="header-content">
      <h2>{title}</h2>
      {subtitle && <p>{subtitle}</p>}
    </div>
  );
}
