import { useMemo } from "react";
import { Button } from "antd";
import Masonry from "react-masonry-css";
import { ProjectItem, SpinLoader } from "../";
import useGlobalConfig from "../../hooks/useGlobalConfig";

interface IProps {
  isLoading?: any;
  projectData?: any[];
  pagination?: any;
  isMobile?: boolean | any;
  setSelectedProject?: any;
  setIsModalVisible?: any;
  changePage?: any;
  chosenPlatformTagId?: any;
  platformTagList?: any;
}

const columnSettings = {
  default: 3,
  767: 2,
};

export default function ProjectList({
  isLoading,
  projectData,
  pagination,
  isMobile,
  setSelectedProject,
  setIsModalVisible,
  changePage,
  chosenPlatformTagId,
  platformTagList,
}: IProps) {
  const { aboutUs } = useGlobalConfig();

  const parseNullContent = useMemo(() => {
    let tagName: string = "";
    if (chosenPlatformTagId) {
      tagName = platformTagList?.find(
        (item: any) => item?.platformTagId === chosenPlatformTagId
      )?.platformTagName;
    }

    return `${
      chosenPlatformTagId
        ? `We have the expertise in <span class="font-bold">${tagName}</span> and would love to work with you.`
        : ""
    }<span class='block'/>Please drop us a message at <a href="mailto:${aboutUs?.email}" class="font-bold">${
      aboutUs?.email
    }</span>`;
  }, [aboutUs, platformTagList, chosenPlatformTagId]);
  return (
    <div className="project-list">
      <div className="content">
        <div className="item-container">
          {isLoading ? (
            <SpinLoader />
          ) : (
            <Masonry
              breakpointCols={columnSettings}
              className="masonry-list"
              columnClassName="masonry-column"
            >
              {projectData?.map((project) => (
                <ProjectItem
                  isMobile={isMobile}
                  item={project}
                  onClick={() => {
                    setSelectedProject(project);
                    setIsModalVisible(true);
                  }}
                />
              ))}
            </Masonry>
          )}
        </div>
        {!isLoading && (
          <>
            {pagination?.next && (
              <Button
                className="btn-more"
                onClick={() => changePage(pagination.page + 1)}
              >
                More Project
              </Button>
            )}
            {pagination?.totalData < 1 && (
              <p
                className="null-content"
                dangerouslySetInnerHTML={{ __html: parseNullContent }}
              />
            )}
          </>
        )}
      </div>
    </div>
  );
}
