import { CareerTagProps } from "./careerTag.type";

export interface EmailProps {
  name: string;
  company: string;
  email: string;
  projectBudget?: string;
  services: string[];
  note: string;
}

export const initialEmail: EmailProps = {
  name: "",
  company: "",
  email: "",
  projectBudget: undefined,
  services: [],
  note: "",
};
