import { PlatformTagProps } from "../../types/platformTag.type";

type Props = {
  item: PlatformTagProps;
  chosenPlatformTagId: String;
  onClick: () => any;
};

export default function PlatformTagFilterItem({
  item,
  chosenPlatformTagId,
  onClick,
}: Props) {
  return (
    <div
      className={`platform-tag-filter-item ${
        chosenPlatformTagId === item.platformTagId ? "active" : ""
      }`}
      onClick={onClick}
    >
      {item.platformTagName}
    </div>
  );
}
