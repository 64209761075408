import React from "react";
import { ItemGoals } from "../";
import { dataGoals } from "../../constants";

export default function Goals() {
  return (
    <div className="goals-about">
      <div className="site-layout-content">
        <div className="items-container">
          {dataGoals?.map((item: any, index: number) => (
            <ItemGoals key={index} item={item} index={index} />
          ))}
        </div>
      </div>
    </div>
  );
}
