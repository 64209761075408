import { PlatformTagFilterItem } from "../";

interface IProps {
  platformTagList?: any[];
  chosenPlatformTagId?: any;
  setChosenPlatformTagId?: any;
  includeAll?: boolean;
}
export default function PlatformTagFilter({
  platformTagList,
  chosenPlatformTagId,
  setChosenPlatformTagId,
  includeAll = true,
}: IProps) {
  return (
    <div className="platform-tag-filter">
      <div className="content">
        <div className="item-container">
          {includeAll && (
            <PlatformTagFilterItem
              item={{
                platformTagName: "All",
                platformTagId: "",
                isPublished: true,
              }}
              chosenPlatformTagId={chosenPlatformTagId}
              onClick={() => setChosenPlatformTagId("")}
            />
          )}
          {platformTagList?.map((tag) => (
            <PlatformTagFilterItem
              item={tag}
              chosenPlatformTagId={chosenPlatformTagId}
              onClick={() => setChosenPlatformTagId(tag.platformTagId || "")}
            />
          ))}
        </div>
      </div>
    </div>
  );
}
