import React from 'react';
import { Col, Divider, Row, Typography, Form, Input, FormInstance, Card, Avatar, Image, Upload, message, Button } from 'antd';
import { IUser } from '../../types/user.type';
import AppLayout from '../../layout/AppLayout';
import { generateFormRules } from '../../helpers/formRules';
import { useHistory, useLocation } from 'react-router-dom';
import AppButton from '../../components/AppButton';
import useAuth from '../../hooks/useAuth';
import FormUploadImage from '../../components/FormUploadImage';
import styled from 'styled-components';
import { getInitialName } from '../../helpers/constant';
import { UploadOutlined } from '@ant-design/icons';

const { Title, Text } = Typography;
type ILocation = {
  do?: 'upload'
}

const EditProfile: React.FC = () => {
    const location = useLocation<ILocation>()
    const history = useHistory()
    const refUpload = React.useRef<any>()
    const formRef = React.useRef<FormInstance<Omit<IUser, 'createdAt' | 'updatedAt'>>>(null)
    const { isLoading, user, saveProfile } = useAuth()
    const [imageUrl, setImageUrl] = React.useState(user.profilePic);
    const [image, setImage] = React.useState<File>()

    React.useEffect(() => {
      if (location.state?.do === 'upload') {
        const element = document.getElementById('fileUploader');

        if (element) {
          message.warn('Please select an image')
          element.click()
        }
      }
    }, [location, refUpload])

    const handleUpdate = async (props: Omit<IUser, 'createdAt' | 'updatedAt' | 'statusLoading'>) => {
      await saveProfile({ ...props, image });
      history.push('/profile')
    }

    return (
      <AppLayout hasSidebar>
        <Card>
          <Row>
            <Col>
              <Title level={2}>Edit Account</Title>
            </Col>
          </Row>
          <Divider />

          <Form
            ref={formRef}
            name="profileForm"
            layout="vertical"
            onFinish={handleUpdate}
            initialValues={{
              name: user.name,
              email: user.email,
              phone: user.phone,
            }}
            autoComplete="off"
          >
            <Row wrap gutter={16}>
              <Col
                lg={8}
                md={8}
                xs={24}
                style={{
                  textAlign: 'center',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                {imageUrl ? (
                  <Image
                    preview={false}
                    width={200}
                    height={200}
                    src={imageUrl}
                    fallback={'/images/blur-image.jpeg'}
                    style={{ borderRadius: '50%' }}
                    placeholder={
                      <Image
                        preview={false}
                        src="/images/blur-image.jpeg"
                        width="100%"
                        height={200}
                      />
                    }
                  />
                ) : (
                  <AppAvatar size="large">
                    {getInitialName(user.name)}
                  </AppAvatar>
                )}

                <Upload
                  ref={refUpload}
                  id="fileUploader"
                  name="file"
                  accept=".jpg,.jpeg,.png"
                  showUploadList={false}
                  beforeUpload={(file) => {
                    const isLtMaxSize = file.size / 1024 / 1024 < 2;
                    if (!isLtMaxSize) {
                      message.error(`Image must smaller than ${2}MB!`);
                    } else {
                      // generate preview image
                      const reader = new FileReader();
                      reader.readAsDataURL(file);
                      reader.onloadend = function (e) {
                        if (e.target && e.target.result) {
                          setImageUrl(e.target.result as string);
                          // if (props.onPreviewChange) {
                          //   props.onPreviewChange(e.target.result as string);
                          // }
                        }
                      };
                      // end generate preview image

                      // props.onChange(file);
                      setImage(file);
                    }

                    return false;
                  }}
                  maxCount={1}
                >
                  <Row
                    justify="center"
                    align="middle"
                    style={{
                      marginTop: 10,
                      cursor: 'pointer',
                      padding: '10px 20px 10px 20px',
                      borderRadius: 30,
                      border: '1px solid black',
                    }}
                  >
                    <UploadOutlined />
                    <Text style={{ marginLeft: 10 }}>Upload Photo</Text>
                  </Row>
                </Upload>
              </Col>

              <Col lg={16} md={16} xs={24}>
                <Form.Item
                  label="Name"
                  name="name"
                  rules={generateFormRules('Name', [
                    'required',
                    'letter-and-space',
                  ])}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label="Phone number"
                  name="phone"
                  rules={generateFormRules('Phone number', [
                    'required',
                    'phoneNumber',
                  ])}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label="Email"
                  name="email"
                  rules={generateFormRules('Email', ['required', 'email'])}
                >
                  <Input disabled />
                </Form.Item>
              </Col>
            </Row>
            <Divider />
            <Row justify="end">
              <AppButton
                onClick={() => history.goBack()}
                style={{
                  width: '100px',
                  border: '1px solid #C5CED6',
                  marginRight: 10,
                }}
              >
                Cancel
              </AppButton>
              <AppButton
                loading={isLoading}
                type="primary"
                htmlType="submit"
                style={{
                  width: '100px',
                }}
                // onClick={() => formRef?.current?.submit()}
              >
                Save
              </AppButton>
            </Row>
          </Form>
        </Card>
      </AppLayout>
    );

};


export default EditProfile;

const AppAvatar = styled(Avatar)`
  width: 200px;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.ash500};
  color: ${({ theme }) => theme.colors.black};
  margin-right: 10px;
  font-size: 80px;
`;