import { useMemo } from "react";
import { HeaderContent } from "../";
import useFetchList from "../../hooks/useFetchList";
import useWindowSize from "../../hooks/useWindowSize";
import { Carousel } from "antd";
import React from "react";

const Clients = React.forwardRef<HTMLInputElement>((props, ref) => {
  const { isMobile } = useWindowSize();

  const { data } = useFetchList<any>({
    endpoint: "clients",
    limit: 100,
    initialQuery: {
      isPublished: "active",
    },
  });

  const parseClients = useMemo(
    () => (isMobile ? [...data]?.splice(0, 6) : data),
    [isMobile, data]
  );

  const width = document.body.clientWidth;

  const carouselSettings = {
    className: "center",
    centerMode: true,
    autoplay: true,
    slidesToShow: 1,
    infinite: true,
    autoplaySpeed: 5000,
    rows: width > 768 ? 2 : 1,
    slidesPerRow: width > 768 ? (width > 1200 ? 7 : 5) : 2,
    draggable: true,
    fade: true,
    dots: true,
  };

  return (
    <div ref={ref} id="clients" className="clients-home site-layout-content">
      <HeaderContent
        title="Client"
        subtitle="Customer satisfaction is our major goal, Some of our happy customers."
      />
      <Carousel {...carouselSettings} className="slider-container">
        {parseClients?.map((item: any, index: number) => (
          <div key={index} className="client-item-container">
            <img
              src={item.imageUrl}
              alt={item.clientName}
              className="client-item-img"
            />
          </div>
        ))}
      </Carousel>
    </div>
  );
});

export default Clients;
