import { dataTeams } from "../../constants";
import { SliderTeams } from "../";

export default function Teams() {
  return (
    <div className="teams-about">
      <div className="content site-layout-content">
        <h3>The Core Team</h3>
        <p>Alone we can do so little, together we can do so much</p>
        <SliderTeams data={dataTeams} />
      </div>
    </div>
  );
}
