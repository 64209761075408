import React, {useEffect, useState} from 'react';
import { httpRequest } from '../helpers/api';
import { IHttpResponse } from '../helpers/pagination';
import AppLayout from '../layout/AppLayout';
import { ITnC } from '../types/tnc.type';
import { message } from 'antd';
import { getErrorMessage } from '../helpers/errorHandler';

const TermAndCondition: React.FC = () => {
  const [tnc, setTnC] = useState<ITnC>()

  useEffect(() => {
    init();
  }, [])

  const init = async () => {
    try {
      const res = await httpRequest.get<IHttpResponse<ITnC>>(
        `terms-and-conditions`
      );

      setTnC(res.data.payload)
    } catch(err) {
      message.error(getErrorMessage(err))
    }
  }

  return (
    <AppLayout showPageHeader={true} pageHeaderTitle="Terms and Conditions" >
      {tnc ?
        <div dangerouslySetInnerHTML={{__html: tnc.tncText}} />
        : false}
    </AppLayout>
  );
};

export default TermAndCondition;
