interface IProps {
  item?: any;
}
export default function ItemTeams({ item }: IProps) {
  return (
    <div className="item-teams">
      <div className="img-container">
        <img src={item?.img} alt="img-teams" />
      </div>
      <div className="content">
        <h4>{item?.name}</h4>
        <h5>{item?.title}</h5>
        <div
          className="desc"
          dangerouslySetInnerHTML={{ __html: item?.desc }}
        />
      </div>
    </div>
  );
}
