import { HeaderContent } from "../";
import { Carousel } from "antd";
import useWindowSize from "../../hooks/useWindowSize";
import { dataProjects } from "../../constants";
import { ItemProjects } from "../";
import useFetchList from "../../hooks/useFetchList";
import { useEffect, useMemo } from "react";
import { ProjectProps } from "../../types/project.type";
import { Link } from "react-router-dom";

export default function Projects() {
  const { width } = useWindowSize();

  const {
    isLoading,
    data,
    pagination,
    query,
    setData,
    setSearch,
    setQuery,
    setQueryAndResetData,
    changePage,
  } = useFetchList<ProjectProps>({
    endpoint: "projects",
    limit: 6,
    initialQuery: {
      filterStatus: "active",
      isFeatured: "active",
    },
  });

  const dataProjects = useMemo(() => {
    if (data.length === 0) return;

    const processedData = [...data];
    // const processedData = data.map((d) => {
    //   const newData = { ...d };
    //   if (newData.platformTags && newData.platformTags.length > 2) {
    //     newData.platformTags = newData.platformTags.slice(0, 2);
    //   }
    //   return newData;
    // });

    let currentIndex = processedData.length,
      randomIndex;

    while (currentIndex != 0) {
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;

      [processedData[currentIndex], processedData[randomIndex]] = [
        processedData[randomIndex],
        processedData[currentIndex],
      ];
    }

    return processedData;
  }, [data]);

  // useEffect(() => {
  //   console.log("Projects");
  //   console.log(data);
  // }, [data]);

  return (
    <div className="projects-home site-layout-content">
      <HeaderContent
        title="Projects"
        subtitle="We grow through creativity, invention, and innovation"
      />

      <div className="grid-container">
        {dataProjects?.map((item: any, index: number) => (
          <ItemProjects
            key={index}
            item={item}
            index={index}
            screenWidth={width}
          />
        ))}
      </div>
      <div className="see-projects-container">
        <Link to="/projects" className="see-projects-link">
          <span>See All Projects</span>
          <span>{">"}</span>
        </Link>
      </div>
    </div>
  );
}
