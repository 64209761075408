import { CareerTagProps } from "./careerTag.type";

export interface CareerProps {
  careerId: string;
  positionName: string;
  responsibilities: string;
  requirement: string;
  isPublished: boolean;
  updatedAt?: Date | string;
  createdAt?: Date | string;
  statusLoading?: boolean;
  category?: CareerTagProps[];
}

export const initialCareer: CareerProps = {
  careerId: '',
  positionName: '',
  responsibilities: '',
  requirement: '',
  isPublished: true,
  updatedAt: '',
  createdAt: '',
  statusLoading: false,
  category: []
}