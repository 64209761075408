import { DownOutlined, LockOutlined, LogoutOutlined, OrderedListOutlined, UserOutlined } from '@ant-design/icons'
import { Avatar, Divider, Dropdown, Menu, Modal, Image } from 'antd'
import Text from 'antd/lib/typography/Text'
import React from 'react'
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import styled from 'styled-components'
import { getInitialName } from '../helpers/constant';
import useAuth from '../hooks/useAuth';
import useWindowSize from '../hooks/useWindowSize';

const UserAvatar: React.FC = () => {
  const history = useHistory()
  const {isMobile } = useWindowSize()
  const { user, logout } = useAuth()

  const handleMenuClick = (e: any) => {
    if (e.key === 'logout') {
      Modal.confirm({
        title: 'Confirmation',
        content: 'Are you sure want to logout?',
        onOk: () => {
          logout();
          history.replace({ pathname: '/' });
        },
        okText: `Yes, I'm sure`,
        okType: 'primary'
      })
    } else {
      history.push({ pathname: '/' + e.key });
    }
  }

  const ContentDropdown = (
    <Menu onClick={handleMenuClick}>
      <Menu.ItemGroup title={'Orders'}>
        <Divider style={{ marginTop: 0, marginBottom: 0 }} />
        <Menu.Item key="orders" icon={<OrderedListOutlined />}>
          My Orders
        </Menu.Item>
      </Menu.ItemGroup>
      <Menu.ItemGroup title={'Account'}>
        <Divider style={{ marginTop: 0, marginBottom: 0 }} />
        <Menu.Item key="profile" icon={<UserOutlined />}>
          My Profile
        </Menu.Item>
        <Menu.Item key="profile/change-old-password" icon={<LockOutlined />}>
          Change Password
        </Menu.Item>
        <Menu.Item key="logout" icon={<LogoutOutlined />}>
          Logout
        </Menu.Item>
      </Menu.ItemGroup>
    </Menu>
  );

  const size = isMobile ? 30 : 40;

  const _renderContent = () => (
    <div>
      {user.profilePic ? (
        <AppAvatar
          size={isMobile ? 'default' : 'large'}
          icon={
            <Image
              preview={false}
              height={size}
              width={size}
              src={user.profilePic || '/images/blur-image.jpeg'}
              fallback={'/images/blur-image.jpeg'}
              style={{ objectFit: 'cover', borderRadius: '50%' }}
              placeholder={
                <Image
                  preview={false}
                  src="/images/blur-image.jpeg"
                  width={size}
                  height={size}
                />
              }
            />
          }
        />
      ) : (
        <AppAvatar size={isMobile ? 'default' : 'large'}>
          {getInitialName(user.name)}
        </AppAvatar>
      )}

      {!isMobile && (
        <Text style={{ marginRight: 10 }}>
          {user.name.length > 15
            ? user.name.substring(0, 13) + '...'
            : user.name}
        </Text>
      )}
      {!isMobile && <Icon />}
    </div>
  );

  if (isMobile) {
    return <Link to="/profile">{_renderContent()}</Link>;
  } else {
    return (
      <Dropdown overlay={ContentDropdown}>
        {_renderContent()}
      </Dropdown>
    );
  }
}

const AppAvatar = styled(Avatar)`
  background-color: ${({ theme }) => theme.colors.ash500};
  color: ${({ theme }) => theme.colors.black};
  margin-right: 10px;
`

const Icon = styled(DownOutlined)`
  color: ${({ theme }) => theme.colors.charcoal800};
`

export default UserAvatar