import { DefaultTheme } from 'styled-components';

const theme: DefaultTheme = {
  borderRadius: '10px',
  fontSize: {
    title: '20px',
    body1: '16px',
    body: '14px',
    smallText: '12px',
  },
  fontWeight: {
    regular: '400',
    bold: '600',
  },

  colors: {
    // Main color
    primary: '#0B998E',
    secondary: '#1d2b36',
    black: '#000000',
    white: '#FFFFFF',
    
    // System color
    warning: '#FFC555',

    ash200: '#F9FAFA',
    ash300: '#F1F3F5',
    ash400: '#E5E9EC',
    ash500: '#D5DCE1',
    ash600: '#C5CED6',
    ash700: '#B7C2CC',
    ash800: '#A5B2BD',

    charcoal200: '#93A1B0',
    charcoal300: '#748494',
    charcoal400: '#556575',
    charcoal500: '#405261',
    charcoal600: '#314351',
    charcoal700: '#253642',
    charcoal800: '#1D2B36',
  },
};

export { theme };