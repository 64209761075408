import React, { useEffect, useState } from "react";
import { Button, message } from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import { imgIdea } from "../../assets/images";
import { httpRequest } from "../../helpers/api";
import { EmailProps, initialEmail } from "../../types/email.type";
import { getErrorMessage } from "../../helpers/errorHandler";
import {
  FetchAllHomepageResponse,
  HomepageProps,
} from "../../types/homepage.type";
import { LetsTalkModal } from "../";
import { generateQueryString } from "../../helpers/generateQueryString";

const WeLove = React.forwardRef<HTMLInputElement>((props, ref) => {
  const [data, setData] = useState<Array<any>>();
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [emailData, setEmailData] = useState<EmailProps>(initialEmail);
  const [servicesData, setServicesData] = useState<Array<HomepageProps>>([]);
  const [selectedTags, setSelectedTags] = useState<Array<string>>([]);

  useEffect(() => {
    init();
  }, []);

  const init = async () => {
    const res: any = await httpRequest.get(
      "files" + generateQueryString({ isPublished: true })
    );
    setData(res.data.payload.results);
  };

  const handleButtonClicked = (fileName: string) => {
    console.log(data);
    const fileData = data?.find((datum) => datum.fileName === fileName);
    console.log(fileData);
    if (!fileData) {
      return;
    }
    const win = window.open(fileData.driveLink, "_blank");
    win?.focus();
  };

  React.useEffect(() => {
    const getServicesData = async () => {
      try {
        const res = await httpRequest.get<FetchAllHomepageResponse>(
          `/homepages`
        );

        setServicesData(res.data.payload.results);
      } catch (error) {
        message.error(getErrorMessage(error));
      }
    };
    getServicesData();
  }, []);

  const onClickSend = async () => {
    const selectedService = servicesData.filter((item: any) => {
      if (selectedTags?.includes(item.homepageId)) {
        return item;
      } else return null;
    });
    const newEmailData = {
      ...emailData,
      services: selectedService.map((item) => item.title),
    };

    await httpRequest.post("/contact-us", newEmailData);
    setIsModalVisible(false);
    setEmailData(initialEmail);
    message.success("Email Sent");
  };

  return (
    <div ref={ref} id="get-in-touch" className="we-love-bg-edge">
      <div className="we-love-home site-layout-content">
        <div className="we-love-container">
          <div className="content-container">
            <div className="content">
              <img src={imgIdea} alt="icon-idea" />
              <h3>
                We love help build great projects,
                <span /> Wanna talk about yours?
              </h3>
            </div>
            <div className="action-btn-container">
              <Button
                onClick={() => {
                  setIsModalVisible(true);
                }}
              >
                Lets Talk
              </Button>
              <div>
                <Button
                  type="primary"
                  icon={<DownloadOutlined />}
                  onClick={() => handleButtonClicked("Company Profile")}
                >
                  Company Profile
                </Button>
                <Button
                  type="primary"
                  icon={<DownloadOutlined />}
                  onClick={() => handleButtonClicked("UI/UX Showcase")}
                >
                  Show Case
                </Button>
              </div>
            </div>
          </div>
        </div>
        <LetsTalkModal
          isModalVisible={isModalVisible}
          setIsModalVisible={setIsModalVisible}
          initialEmail={initialEmail}
          setEmailData={setEmailData}
          onClickSend={onClickSend}
          servicesData={servicesData}
          emailData={emailData}
          selectedTags={selectedTags}
          setSelectedTags={setSelectedTags}
        />
      </div>
    </div>
  );
});

export default WeLove;
