import React from 'react'
import { CheckCircleFilled, EnvironmentOutlined, LoadingOutlined, PlusOutlined, SearchOutlined, SlidersOutlined } from '@ant-design/icons';
import { Typography, Input, Image, Row, Col, Button, Divider, Modal, Form } from 'antd';
import styled from 'styled-components';
import moment from 'moment';
import { IMenu, initialMenu } from '../../types/menu.type';
import AppLayout from '../../layout/AppLayout';
import { IAvailability } from '../../types/location.type';
import Slider, { Settings } from 'react-slick';
import { ChevronDown } from 'react-feather';
import AppButton from '../../components/AppButton';
import { isEmpty } from 'lodash';
import useCart, { CartItem } from '../../hooks/useCart';
import useAuth from '../../hooks/useAuth';
import useFetchList from '../../hooks/useFetchList';
import useGlobalSearch from '../../hooks/useGlobalSearch';
import ModalMenuDetail, { AddToCartItem } from '../../components/ModalMenuDetail';
import useModalAuth from '../../hooks/useModalAuth';
import ModalSelectLocation from '../../components/ModalSelectLocation';
import useLocationQuery from '../../hooks/useLocationQuery';
import useWindowSize from '../../hooks/useWindowSize';
import EmptyData from '../../components/EmptyData';
import { theme } from '../../assets/theme';
import FilterMenu from '../../components/FilterMenu';
import { BottomSheet, BottomSheetRef } from 'react-spring-bottom-sheet';
import useGlobalFilter from '../../hooks/useGlobalFilter';
import ModalSelectPickupTime from '../../components/ModalSelectPickupTime';
import useGlobalConfig from '../../hooks/useGlobalConfig';
import Paragraph from 'antd/lib/typography/Paragraph';
import { convertHtmlToText } from '../../helpers/text';

const { Title, Text } = Typography;

type CarouselDate = {
  identifierIndex?: number;
  date: Date;
  isSelected: boolean;
  availabilityDate?: IAvailability;
}
const currentDate = moment().startOf('day')
const now = new Date();

const Menus = () => {
  const sheetRef: any = React.useRef<BottomSheetRef>();
  const { width, isMobile } = useWindowSize();
  const { pickupTimeStart, pickupTimeEnd } = useLocationQuery()

  // const { locations } = useGlobalConfig()
  const { setGlobalKeyword, globalKeyword } = useGlobalSearch()
  const { setIsShowModalCart, addToCart, cartInit, carts } = useCart();
  const { user, isLoggedIn } = useAuth()
  const { setIsShowModalLogin } = useModalAuth()
  const {
    selectedLocation,
    setSelectedLocation,
    setIsShowModalSelectLocation,
    setIsShowModalSelectPickupTime,
  } = useGlobalFilter();

  const [isShowBottomSheetFilter, setIsShowBottomSheetFilter] = React.useState(false)

  const {
    isLoading,
    data: menus,
    pagination,
    setSearch,
    setQueryAndResetData,
    changePage,
  } = useFetchList<IMenu>({
    endpoint: 'menus',
    limit: 6,
    initialQuery: {
      search: '',
      status: '',
      topPicks: '',
      sort: 'most-popular',
      includeTags: [],
      excludeAllergens: [],
      // scheduledAt: moment().format('YYYY-MM-DD').toString(),
    },
    pushData: true,
  });

  const initialDate = new Date();
  if (now.getHours() > 12) {
    initialDate.setDate(initialDate.getDate() + 2)
  } else {
    initialDate.setDate(initialDate.getDate() + 1)
  }
  const [selectedDate, setSelectedDate] = React.useState<CarouselDate>({
    // identifierIndex: undefined,
    date: initialDate,
    isSelected: false,
  })

  const [selectedMenu, setSelectedMenu] = React.useState<IMenu>(initialMenu)

  const [listOfDates, setListOfDates] = React.useState<Array<CarouselDate>>(
    Array.from([0, 1, 2, 3, 4, 5, 6], i => {
      let addDay = new Date().getHours() > 12 ? 2 : 1//;
      const date = moment(currentDate).add(i + addDay, 'days')
      return {
        identifierIndex: i,
        date: date.toDate(),
        isSelected: false,
        availabilityDate: date.isSame(currentDate)
          ? (
            pickupTimeStart && pickupTimeEnd && selectedLocation
              ? selectedLocation.availabilityPickups.find(avail => avail.startAt === pickupTimeStart && avail.endAt === pickupTimeEnd)
              : undefined
          )
          : undefined,
      };
    })
  )

  const [isModalMenuVisible, setisModalMenuVisible] = React.useState<boolean>(false)
  const [isModalWarningVisible, setisModalWarningVisible] = React.useState<boolean>(false)

  React.useEffect(() => {
    setSearch(globalKeyword)
  }, [globalKeyword])

  // React.useEffect(() => {
  //   if (!selectedLocation.locationId) {
  //     if (carts.length > 0 && carts[0].meta?.pickupFromLocationId) {
  //       const findOne = locations.find(
  //         (item) => item.locationId === carts[0].meta?.pickupFromLocationId
  //       );
  //       if (findOne) {
  //         setSelectedLocation(findOne);
  //       }
  //     } else if (locations.length > 0) {
  //       setSelectedLocation(locations[0])
  //     }
  //   }
  // }, [locations, carts])

  React.useEffect(() => {
    const list = listOfDates.map(item => {
      if (item.identifierIndex === selectedDate.identifierIndex) {
        item.isSelected = true
        item.availabilityDate = selectedLocation.availabilityPickups.length === 1 ? selectedLocation.availabilityPickups[0] : undefined
      } else {
        item.isSelected = false
        item.availabilityDate = undefined
      }
      return item;
    })
    setListOfDates(list)

    if (selectedDate.identifierIndex) {
      setQueryAndResetData({
        locationId: selectedLocation.locationId,
        scheduledUntil: '',
        scheduledAt: moment(selectedDate.date)
          .format('YYYY-MM-DD')
          .toString(),
      });
    } else {
      setQueryAndResetData({
        locationId: selectedLocation.locationId,
        scheduledUntil: moment(currentDate).add(7, 'day')
          .format('YYYY-MM-DD')
          .toString(),
        scheduledAt: moment(currentDate).add(1, 'day')
          .format('YYYY-MM-DD')
          .toString(),
      });
    }
  }, [selectedLocation])

  React.useEffect(() => {
    if (isLoggedIn) {
      cartInit()
    }
  }, [])

  React.useEffect(() => {
    if (isLoggedIn) {
      cartInit();
    }
  }, [isLoggedIn])

  const handleClickDateCarousel = (dateProps: any) => {
    if (isEmpty(selectedLocation.locationId)) {
      setisModalWarningVisible(true)
    } else {
      if (selectedLocation.availabilityPickups.length === 1) {
        setSelectedDate({
          ...dateProps,
        })
        setQueryAndResetData({
          locationId: selectedLocation.locationId,
          scheduledUntil: '',
          scheduledAt: moment(dateProps.date)
            .format('YYYY-MM-DD')
            .toString(),
        });
        handleChangePickupTime(selectedLocation.availabilityPickups[0], dateProps)

      } else {
        setIsShowModalSelectPickupTime(true)
        setSelectedDate({
          ...dateProps
        })
        setQueryAndResetData({
          locationId: selectedLocation.locationId,
          scheduledUntil: '',
          scheduledAt: moment(dateProps.date)
            .format('YYYY-MM-DD')
            .toString(),
        });
      }
    }
  }

  const handleChangePickupTime = (newTime: IAvailability, dateProps: CarouselDate) => {
    const list = listOfDates.map(item => {
      if (item.identifierIndex === dateProps.identifierIndex) {
        item.isSelected = true
        item.availabilityDate = newTime
      } else {
        item.isSelected = false
        // item.availabilityDate = undefined
      }
      return item;
    })
    setListOfDates(list)
  }

  const getLocationValueInput = () => {
    if (selectedLocation.locationId) {
      return `${selectedLocation.locationName} - ${selectedLocation.locationAddress}`
    } else {
      return `Choose Your Pickup Location First`
    }
  }

  const handleOnClickMenu = (menu: IMenu) => {
    if (isEmpty(selectedLocation.locationId) || !listOfDates.find(item => item.availabilityDate)) {
      setisModalWarningVisible(true)
    } else {
      console.info('handleOnClickMenu', menu);
      setSelectedMenu(menu)
      setisModalMenuVisible(true)
    }
  }

  const handleClickAddToCart = (newData: AddToCartItem) => {
    const availTime = listOfDates.find(item => item.isSelected);

    const variant = selectedMenu.variants.find(
      (item) => item.menuVariantId === newData.menuVariantId
    );

    if (availTime && selectedDate.date && variant) {
      const addedItem: CartItem = {
        productId: selectedMenu.menuId,
        productVariantId: newData.menuVariantId,
        quantity: newData.qty,
        buyerId: user.userId,

        productDetail: {
          ...selectedMenu,
          variant,
        },
        meta: {
          pickupFromLocationId: selectedLocation.locationId,
          pickupScheduledAt: moment(selectedDate.date).format('YYYY-MM-DD'),
          pickupTime: {
            startAt: availTime?.availabilityDate?.startAt,
            endAt: availTime?.availabilityDate?.endAt
          }
        }
      }
      addToCart(addedItem)
      setisModalMenuVisible(false)
    }
  }

  const newListOfDates = listOfDates.map((itemDate) => {
    return {
      ...itemDate,
      totalExistInCart: carts.filter(
        (cartItem) =>
          cartItem.meta?.pickupFromLocationId === selectedLocation.locationId &&
          cartItem.meta?.pickupScheduledAt ===
          moment(itemDate.date).format('YYYY-MM-DD')
      ).length,
    }
  });
  const isProductExistInCart = (productId: string) => carts.find(item => item.productId === productId && item.meta?.pickupFromLocationId === selectedLocation.locationId && item.meta?.pickupScheduledAt === moment(selectedDate.date).format('YYYY-MM-DD'))

  const _renderFilterContent = () => (
    <FilterMenu
      needConfirm={isMobile}
      onChangeFilter={(newFilter) => {
        setQueryAndResetData({
          priceStart: newFilter.minPrice,
          priceEnd: newFilter.maxPrice,
          sort: newFilter.sortBy,
          includeTags: newFilter.tags,
          excludeAllergens: newFilter.allergens,
        });
      }}
    />
  );

  return (
    <AppLayout>
      <Row wrap gutter={16} style={{ marginRight: 0 }}>
        {/** FILTER */}
        {!isMobile && (
          <Col xs={24} lg={4}>
            {_renderFilterContent()}
          </Col>
        )}
        {/** END FILTER */}

        {/** CHOOSE SCHEDULE */}
        <Col offset={isMobile ? 0 : 1} xs={24} lg={19}>
          {!isMobile && (
            <>
              <Row>
                <Title level={3}>Pick Your Schedule</Title>
              </Row>
              <Row style={{ marginBottom: 20 }}>
                <Row
                  style={{
                    borderRadius: '100px',
                    border: '1px lightgrey solid',
                    width: '100%',
                    cursor: 'pointer',
                    padding: '0px 10px 0px 10px',
                  }}
                  align="middle"
                  justify="space-between"
                  onClick={() => setIsShowModalSelectLocation(true)}
                >
                  <Col>
                    <Row align="middle">
                      <EnvironmentOutlined style={{ color: '#556575' }} />
                      <Text
                        style={{
                          padding: '10px 0px 10px 0px',
                          marginLeft: 10,
                          marginRight: 10,
                          paddingRight: 10,
                          color: '#556575',
                          borderRight: '1px solid lightgrey',
                        }}
                      >
                        Pickup from
                      </Text>

                      {getLocationValueInput()}
                    </Row>
                  </Col>

                  <ChevronDown />
                </Row>
              </Row>
            </>
          )}

          {/** CHOOSE DATE */}
          {newListOfDates.length > 0 && (
            <div
              style={{
                border: '10px',
                marginBottom: 20,
              }}
            >
              <Slider
                slidesToShow={width < 767 ? 3 : width < 1000 ? 5 : 7}
                arrows={false}
                infinite={false}
              >
                {newListOfDates.map((dateProps) => {
                  return (
                    <div
                      key={dateProps.identifierIndex}
                      onClick={() => handleClickDateCarousel(dateProps)}
                    >
                      <Row
                        style={{
                          height: isMobile ? 60 : 70,
                          borderWidth: 1,
                          borderStyle: 'solid',
                          borderColor: dateProps.isSelected
                            ? 'red'
                            : 'lightgrey',
                          borderRadius: '10px',
                          marginRight: 10,
                          padding: '5px 10px',
                        }}
                      >
                        <Col
                          xs={24}
                          style={{
                            height: '100%',
                            display: 'flex',
                            justifyContent: 'space-between',
                            flexDirection: 'column',
                          }}
                        >
                          <Row justify="space-between">
                            <Text
                              style={{
                                fontSize: '14px',
                                fontWeight: 600,
                              }}
                            >
                              {moment(dateProps.date).format('ddd D')}
                            </Text>

                            {dateProps.totalExistInCart > 0 && (
                              <div
                                style={{
                                  width: 18,
                                  height: 18,
                                  borderRadius: 18,
                                  background: theme.colors.primary,
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                }}
                              >
                                <Text
                                  style={{ color: '#FFFFFF', fontSize: 10 }}
                                >
                                  {dateProps.totalExistInCart}
                                </Text>
                              </div>
                            )}
                          </Row>

                          <Row>
                            {!isEmpty(dateProps.availabilityDate) ? (
                              <Text
                                style={{
                                  fontSize: '10px',
                                  color: dateProps.date ? 'red' : 'darkgrey',
                                }}
                              >
                                {dateProps.availabilityDate?.startAt} -
                                {dateProps.availabilityDate?.endAt}
                              </Text>
                            ) : (
                              <Text
                                style={{
                                  fontSize: '10px',
                                  color: 'darkgrey',
                                }}
                              >
                                Time Not Set
                              </Text>
                            )}
                          </Row>
                        </Col>
                      </Row>
                    </div>
                  );
                })}
              </Slider>
            </div>
          )}
          {/** END CHOOSE DATE */}

          {isMobile && (
            <Row style={{ marginBottom: 20 }}>
              <Form
                name="form"
                layout="vertical"
                initialValues={{
                  search: '',
                }}
                autoComplete="off"
                style={{ flex: 1 }}
              >
                <Form.Item name="search" rules={[]} style={{ marginBottom: 0 }}>
                  <Input
                    style={{ borderRadius: 50 }}
                    size="middle"
                    placeholder="What are you eating?"
                    prefix={<SearchOutlined style={{ color: 'darkgrey' }} />}
                    suffix={
                      <div
                        onClick={() => {
                          setIsShowBottomSheetFilter(true);
                        }}
                      >
                        <SlidersOutlined style={{ fontSize: 18 }} />
                      </div>
                    }
                    onChange={(e) => setGlobalKeyword(e.target.value)}
                    value={globalKeyword}
                  />
                </Form.Item>
              </Form>
            </Row>
          )}

          {!isMobile && (
            <Title level={3}>
              Choose food for {moment(selectedDate.date).format('dddd, MMM D')}
            </Title>
          )}
          <div>
            {isLoading && (
              <Row justify="center">
                <LoadingOutlined />
              </Row>
            )}
            {!isLoading && menus.length === 0 && (
              <Row justify="center">
                <EmptyData />
              </Row>
            )}

            <div
              style={{
                display: 'grid',
                flexWrap: 'wrap',
                gridTemplateColumns: isMobile ? '1fr' : '1fr 1fr 1fr',
                alignItems: 'flex-start',
                gap: 15,
                // width: 700
              }}
            >
              {menus.length > 0 &&
                menus.map((menu) => (
                  <div
                    onClick={() => handleOnClickMenu(menu)}
                    key={menu.menuId}
                    style={{
                      display: 'flex',
                      flex: 1,
                      height: isMobile ? undefined : '100%',
                      flexDirection: 'column',
                    }}
                  >
                    <div
                      style={{
                        flex: 1,
                      }}
                    >
                      <Image
                        preview={false}
                        height={isMobile ? 200 : 162}
                        width="100%"
                        src={
                          menu.images && menu.images.length > 0
                            ? menu.images[0].imageUrl ||
                              '/images/select-image.jpg'
                            : '/images/select-image.jpg'
                        }
                        fallback={'/images/blur-image.jpeg'}
                        style={{ borderRadius: 10, objectFit: 'cover' }}
                      />
                      {isProductExistInCart(menu.menuId) && (
                        <Row
                          justify="center"
                          align="middle"
                          style={{
                            padding: '3px 5px',
                            background: '#FFFFFF',
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            borderRadius: 20,
                          }}
                        >
                          <CheckCircleFilled
                            style={{
                              color: theme.colors.primary,
                              fontSize: 11,
                            }}
                          />
                          <Text style={{ marginLeft: 3, fontSize: 10 }}>
                            Added
                          </Text>
                        </Row>
                      )}
                      <div
                        style={{
                          fontSize: 18,
                          fontWeight: 'bold',
                          marginBottom: 10,
                        }}
                      >
                        <Text
                          style={{
                            fontSize: '20px',
                            letterSpacing: '-0.02em',
                          }}
                        >
                          {menu.name}
                        </Text>
                      </div>
                      <Paragraph
                        type="secondary"
                        ellipsis={{
                          rows: 3,
                        }}
                      >
                        {convertHtmlToText(menu.description)}
                      </Paragraph>
                    </div>

                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        marginTop: 10,
                        flexWrap: 'wrap',
                      }}
                    >
                      <div>
                        <Text style={{ fontSize: 16 }}>
                          ${menu.variants[0].price.toFixed(2)}
                        </Text>
                        {menu.variants.length > 1 && (
                          <>
                            <Divider type="vertical" />
                            <Text type="secondary" style={{ fontSize: '14px' }}>
                              {menu.variants.length} Variants
                            </Text>
                          </>
                        )}
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <AppButton
                          size={isMobile ? 'small' : 'middle'}
                          style={{
                            borderRadius: 40,
                            backgroundColor: 'white',
                            border: '1px',
                            borderStyle: 'solid',
                            borderColor: '#0B998E',
                            color: '#0B998E',
                            width: '100px',
                            height: '38px',
                            fontSize: '12px',
                          }}
                          icon={<PlusOutlined />}
                        >
                          {isMobile ? 'Add' : 'Add Menu'}
                        </AppButton>
                      </div>
                    </div>
                  </div>
                ))}
            </div>

            {/* <Row wrap gutter={15}>
              {menus.length > 0 &&
                menus.map((menu) => {
                  return (
                    <Col
                      key={menu.menuId}
                      xs={24}
                      md={12}
                      lg={8}
                      onClick={() => handleOnClickMenu(menu)}
                      style={
                        isMobile
                          ? {
                            borderBottom: '1px #F2F2F2 solid',
                            marginBottom: 20,
                            paddingBottom: 20,
                          }
                          : {
                            height: '500px',
                            flexDirection: 'column',
                            justifyContent: 'space-between'
                          }
                      }
                    >
                      <Row
                        style={{
                          height: '400px'
                        }}
                      >
                        <Col xs={6} md={24} lg={24}>
                          <Image
                            preview={false}
                            height={isMobile ? '85%' : 162}
                            width="100%"
                            src={
                              menu.images && menu.images.length > 0
                                ? menu.images[0].imageUrl ||
                                '/images/select-image.jpg'
                                : '/images/select-image.jpg'
                            }
                            fallback={'/images/blur-image.jpeg'}
                            style={{ borderRadius: 10, objectFit: 'cover' }}
                          />

                          {isProductExistInCart(menu.menuId) && (
                            <Row
                              justify="center"
                              align="middle"
                              style={{
                                padding: '3px 5px',
                                background: '#FFFFFF',
                                position: 'absolute',
                                right: 8,
                                top: 8,
                                borderRadius: 20,
                              }}
                            >
                              <CheckCircleFilled
                                style={{
                                  color: theme.colors.primary,
                                  fontSize: 11,
                                }}
                              />
                              <Text style={{ marginLeft: 3, fontSize: 10 }}>
                                Added
                              </Text>
                            </Row>
                          )}
                          <Row>
                            <Text
                              style={{
                                fontSize: '20px',
                                letterSpacing: '-0.02em',
                              }}
                            >
                              {menu.name}
                            </Text>
                          </Row>

                          <Row>
                            <Text type="secondary">
                              {
                                // htmlConvert(menu.description)
                                < HTMLPreview
                                  html={
                                    menu.description.length > 100
                                      ? menu.description.substring(0, 98) +
                                      '...'
                                      : menu.description
                                  }
                                />
                              }
                            </Text>
                          </Row>
                        </Col>
                      </Row>

                      <Row>
                        <Col
                          xs={18}
                          md={24}
                          lg={24}
                          style={{ padding: '0px 10px 0px 10px' }}
                        >
                          <Row align="middle">
                            <Col span={14}>
                              <Text style={{ fontSize: 16 }}>
                                ${(menu.variants[0].price).toFixed(2)}
                              </Text>
                              {menu.variants.length > 1 && (
                                <>
                                  <Divider type="vertical" />
                                  <Text
                                    type="secondary"
                                    style={{ fontSize: '14px' }}
                                  >
                                    {menu.variants.length} Variants
                                  </Text>
                                </>
                              )}
                            </Col>
                            <Col
                              span={10}
                              style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'flex-end',
                              }}
                            >
                              <AppButton
                                // size={isMobile ? 'small' : 'middle'}
                                style={{
                                  borderRadius: 40,
                                  backgroundColor: 'white',
                                  border: '1px',
                                  borderStyle: 'solid',
                                  borderColor: '#0B998E',
                                  color: '#0B998E',
                                  width: '100px',
                                  height: '38px',
                                  fontSize: '12px'
                                }}
                                icon={<PlusOutlined />}
                              >
                                {isMobile ? 'Add' : 'Add Menu'}
                              </AppButton>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                  );
                })}
            </Row> */}
          </div>
        </Col>
        {/** END CHOOSE SCHEDULE */}
      </Row>

      {pagination.next ? (
        <Row justify="center">
          <Button
            onClick={() => changePage(pagination.page + 1)}
            type="text"
            size="small"
          >
            Load More
          </Button>
        </Row>
      ) : (
        false
      )}

      {/** Modal Location */}
      <ModalSelectLocation />

      {/** Modal Availability */}
      <ModalSelectPickupTime
        onChange={(newItem: IAvailability) =>
          handleChangePickupTime(newItem, selectedDate)
        }
      />

      {isModalMenuVisible && (
        <ModalMenuDetail
          data={selectedMenu}
          onCancel={() => {
            setisModalMenuVisible(false);
          }}
          onAddToCart={(newData) => {
            if (!isLoggedIn) {
              setisModalMenuVisible(false);
              setIsShowModalLogin(true);
            } else {
              handleClickAddToCart(newData);
            }
          }}
        />
      )}

      {/** Modal Warning */}
      <Modal
        title={`Warning`}
        visible={isModalWarningVisible}
        footer={true}
        onCancel={() => {
          setisModalWarningVisible(false);
        }}
        style={{
          borderRadius: '100px',
        }}
        width={isMobile ? '90%' : 500}
      >
        <Text>
          Please Choose {selectedLocation ? 'Date to pickup' : 'Location'}{' '}
          Before Continue
        </Text>
      </Modal>

      {/** FILTER BOTTOM SHEET */}
      <BottomSheet
        open={isShowBottomSheetFilter}
        ref={sheetRef}
        onDismiss={() => setIsShowBottomSheetFilter(false)}
      >
        <div style={{ padding: '10px 20px' }}>{_renderFilterContent()}</div>
      </BottomSheet>
      {/** END FILTER BOTTOM SHEET */}

      {isMobile && carts.length > 0 && (
        <Row
          align="middle"
          style={{
            width: '100%',
            position: 'fixed',
            bottom: 20,
            padding: 20,
          }}
        >
          <AppButton
            block
            type="primary"
            onClick={() => {
              setIsShowModalCart(true);
            }}
          >
            <Row
              justify="space-between"
              align="middle"
              style={{ width: '100%' }}
            >
              <Text style={{ color: theme.colors.white }}>View Orders</Text>
              <div
                style={{
                  width: 18,
                  height: 18,
                  borderRadius: 18,
                  background: theme.colors.white,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Text style={{ color: theme.colors.primary, fontSize: 10 }}>
                  {carts.length}
                </Text>
              </div>
            </Row>
          </AppButton>
        </Row>
      )}
    </AppLayout>
  );
}

export const settings: Settings = {
  slidesToShow: 1,
  infinite: true,
  speed: 500,
  rows: 1,
  slidesPerRow: 1,
  arrows: false
};

export const ContainerFilter = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  margin-bottom: 15px;
`

export default Menus