import React from 'react';
import { Typography } from 'antd';
import styled from 'styled-components';
import useWindowSize from '../hooks/useWindowSize';
const { Title, Text } = Typography;

type Props = {
  title: string;
  subtitle?: string;
  componentUnderSubtitle?: any;
};
export default function PageHeader(props: Props) {
  const {isMobile} = useWindowSize()
  return (
    <CustomDiv style={isMobile ? {paddingTop: 50, textAlign: 'left'} : {paddingTop: 100, textAlign: 'center'}}>
      <Title style={{ color: '#FFFFFF' }}>{props.title}</Title>
      {!!props.subtitle && (
        <Text style={{ color: '#FFFFFF', fontWeight: 'bold' }}>
          {props.subtitle}
        </Text>
      )}
      {props.componentUnderSubtitle ? props.componentUnderSubtitle : false}
    </CustomDiv>
  );
}

const CustomDiv = styled.div`
  background: ${({ theme }) =>
    'url(/images/bg-circle.svg) no-repeat center ' + theme.colors.primary};
  background-size: cover;
  width: 100%;
  height: 250px;
  padding: 20px;
`;
