import React from "react";

interface IProps {
  item?: any;
  index?: number;
}
export default function ItemValues({ item, index }: IProps) {
  return (
    <div className="item-values">
      <span />
      <div className="icon-container">
        <img src={item?.icon} alt={`item-values_${index}`} />
      </div>
      <div className="content">
        <p>{item?.desc}</p>
      </div>
    </div>
  );
}
