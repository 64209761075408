import { Modal, Button } from "antd";
import useWindowSize from "../../hooks/useWindowSize";
import useGlobalConfig from "../../hooks/useGlobalConfig";
interface IProps {
  isModalVisible?: boolean;
  setIsModalVisible?: any;
  selectedCareer?: any;
}

export default function CareerDetailModal({
  isModalVisible,
  setIsModalVisible,
  selectedCareer,
}: IProps) {
  const { isMobile } = useWindowSize();
  const { aboutUs } = useGlobalConfig();
  return (
    <Modal
      className="career-detail-modal"
      visible={isModalVisible}
      onCancel={() => setIsModalVisible(false)}
      onOk={() => setIsModalVisible(false)}
      footer={null}
      width={isMobile ? "90%" : "600px"}
      bodyStyle={{ padding: 0 }}
    >
      <div className="content">
        <h3>{selectedCareer.positionName}</h3>
        <h4>Responsibilities</h4>
        <div
          dangerouslySetInnerHTML={{
            __html: selectedCareer?.responsibilities,
          }}
        />
        <h4>Requirement</h4>
        <div
          dangerouslySetInnerHTML={{ __html: selectedCareer?.requirement }}
        />
        <div style={{ paddingTop: "2%" }}>
          <Button
            style={{
              minWidth: "100%",
              backgroundColor: "#0B998E",
              color: "white",
            }}
          >
            <a
              href={`mailto:${aboutUs?.emailCareer}`}
              target="_blank"
              rel="noreferrer"
            >
              Apply via e-mail
            </a>
          </Button>
        </div>
      </div>
    </Modal>
  );
}
