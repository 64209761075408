import { PlatformTagProps } from "./platformTag.type";

export interface ProjectProps {
  projectId: string;
  title: string;
  subtitle: string;
  description: string;
  platform?: any[];
  coverImage?: {
    fileId: string;
    imageUrl: string
  };
  galleryImages?: {
    fileId: string;
    imageUrl: string
  }[];
  backgroundColor: string;
  playstoreLink?: string;
  appstoreLink?: string;
  webLink?: string;
  isActive: boolean;
  isFeatured: boolean;
  updatedAt?: Date;
  createdAt?: Date;
  statusLoading?: boolean;
  platformTags?: PlatformTagProps[];
}

export const initialProject: ProjectProps = {
  projectId: '',
  title: '',
  subtitle: '',
  description: '',
  platform: [],
  galleryImages: [],
  backgroundColor: '000000',
  isActive: false,
  isFeatured: false,
  platformTags: [],
}