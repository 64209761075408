import React from "react";
import { imgLogoWhite } from "../../assets/images";
export default function Brief() {
  return (
    <div className="brief-about" style={{ backgroundColor: "#0F948A" }}>
      <div className="content site-layout-content">
        <div className="logo-container">
          <img src={imgLogoWhite} alt="qbit_logo_white" />
        </div>
        <p>
          PT. Vanz Inovatif Teknologi was established in July 2017 under the
          service name of “qbit”, with the aim of providing comprehensive
          quantum based IT Solution. We develop product and project with unique
          and creative solutions that meet the clients’ expectations not only by
          realizing the clients’ business objectives, but also by considering
          the effective approach in IT solution.
        </p>
      </div>
    </div>
  );
}
