import React from 'react';
import AppLayout from '../layout/AppLayout';
import { Card, Form, FormInstance, Input, message, Typography } from 'antd';
import { generateFormRules } from '../helpers/formRules';
import { MailOutlined } from '@ant-design/icons';
import AppButton from '../components/AppButton';
import { getErrorMessage } from '../helpers/errorHandler';
import { Link } from 'react-router-dom';
import axios from 'axios';
import useWindowSize from '../hooks/useWindowSize';

const {Title, Text} = Typography;

const ForgotPassword: React.FC = () => {
  const {isMobile} = useWindowSize()
  const formRef =
    React.useRef<FormInstance<{ email: string }>>(null);

  const [isShowSuccessMessage, setIsShowSuccessMessage] = React.useState(false)
  const [isLoading, setIsLoading] = React.useState(false)
  const onSubmit = async (values: { email: string }) => {
    try {
      setIsLoading(true);

      await axios.post('/auth/forgot-password/confirmation-link', {
        email: values.email,
        platform: 'web'
      })

      setIsShowSuccessMessage(true);
      setIsLoading(false);
    } catch (err) {
      message.error(getErrorMessage(err))
      setIsLoading(false)
    }
  }

  return (
    <AppLayout>
      {isShowSuccessMessage ? (
        <ForgotPasswordSuccess onPressTryAgain={() => setIsShowSuccessMessage(false)} />
      ) : (
        <div style={{ textAlign: '-webkit-center' as any }}>
          <Card style={{ width: isMobile ? undefined : 500, textAlign: 'center' }} bordered={false}>
            <Title>Forgot Password</Title>
            <Text>
              If you forget your password, please enter the email used to sign in,
              then our system will send a link to reset your password via email
            </Text>

            <Form
              ref={formRef}
              layout="vertical"
              name="basic"
              initialValues={{
                email: '',
              }}
              onFinish={onSubmit}
              style={{ marginTop: 30 }}
            >
              <Form.Item
                name="email"
                label="Email address"
                rules={generateFormRules('Email address', ['required', 'email'])}
              >
                <Input
                  size="large"
                  type="email"
                  prefix={<MailOutlined />}
                  placeholder="Enter your email address"
                />
              </Form.Item>

              <Form.Item>
                <AppButton
                  loading={isLoading}
                  size="large"
                  type="primary"
                  htmlType="submit"
                  style={{ width: '100%' }}
                >
                  Send Reset Password Instructions
                </AppButton>
              </Form.Item>
            </Form>
          </Card>
        </div>
      )}
    </AppLayout>
  );
};

const ForgotPasswordSuccess = (props: { onPressTryAgain: () => void }) => {
  const {isMobile} = useWindowSize()
  return (
    <div style={{ textAlign: '-webkit-center' as any }}>
      <Card
        style={{ width: isMobile ? undefined : 500, textAlign: 'center' }}
        bordered={false}
      >
        <div
          style={{
            margin: '0 auto',
            background: 'lightgreen',
            width: 50,
            justifyContent: 'center',
            paddingTop: 10,
            paddingBottom: 10,
            borderRadius: 5,
          }}
        >
          <MailOutlined style={{ fontSize: 20, color: 'green' }} />
        </div>

        <Title>Check your email</Title>
        <Text>
          We have sent instructions to reset the password to your email, please
          follow the steps to reset password
        </Text>

        <div style={{ marginTop: 50 }}>
          <Text style={{ color: 'grey' }}>
            Did not recieve the email? try check your spam inbox or{' '}
            <Link
              style={{
                color: '#0B998E',
                fontWeight: 'bold',
                textDecoration: 'none',
              }}
              onClick={() => {
                props.onPressTryAgain();
              }}
              to="#"
            >
              Try another email address
            </Link>
          </Text>
        </div>
      </Card>
    </div>
  );
}

export default ForgotPassword;
