import React, { useEffect } from 'react';
import { createGlobalState } from 'react-hooks-global-state';
import { ILocation, initialAvailability, initialLocation, IAvailability } from '../types/location.type';
import useGlobalConfig from './useGlobalConfig';

type State = {
  isShowModalSelectLocation: boolean;
  selectedLocation: ILocation;

  isShowModalSelectPickupTime: boolean;
  // selectedPickupTime: IAvailability;
};

const initialState: State = {
  isShowModalSelectLocation: false,
  selectedLocation: { ...initialLocation },

  isShowModalSelectPickupTime: false,
  // selectedPickupTime: { ...initialAvailability }
};
const { useGlobalState } = createGlobalState(initialState);

export default function useGlobalFilter() {
  const [isShowModalSelectLocation, setIsShowModalSelectLocation] =
    useGlobalState('isShowModalSelectLocation');
  const [selectedLocation, setSelectedLocation] =
    useGlobalState('selectedLocation');

  const [isShowModalSelectPickupTime, setIsShowModalSelectPickupTime] =
    useGlobalState('isShowModalSelectPickupTime');
  // const [selectedPickupTime, setSelectedPickupTime] =
  //   useGlobalState('selectedPickupTime');

  return {
    isShowModalSelectLocation,
    selectedLocation,
    setSelectedLocation,
    setIsShowModalSelectLocation,

    isShowModalSelectPickupTime,
    // selectedPickupTime,
    // setSelectedPickupTime,
    setIsShowModalSelectPickupTime,
  };
}
