import { message } from "antd";
import React from "react";
import { useDebounce } from "use-debounce/lib";
import { httpRequest } from "../helpers/api";
import { getErrorMessage } from "../helpers/errorHandler";
import { generateQueryString } from "../helpers/generateQueryString";
import {
  IHttpResponse,
  INITIAL_PAGINATION,
  INITIAL_QUERY,
  IPagination,
  IPayloadPagination,
  IQuery,
} from "../helpers/pagination";
import _ from "lodash";

type Props = {
  endpoint: string;
  initialQuery?: Object;
  limit?: number;
  pushData?: boolean;
  columnId?: string;
};

type IFetchQuery = IQuery & { [key: string]: any };

const DEFAULT_LIMIT = 25;

export default function useFetchList<DataType>(props: Props) {
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [data, setData] = React.useState<Array<DataType>>([]);
  const [pagination, setPagination] = React.useState<IPagination>({
    ...INITIAL_PAGINATION,
  });
  const [query, setQuery] = React.useState<IFetchQuery>({
    ...INITIAL_QUERY,
    limit: props.limit || DEFAULT_LIMIT,
    ...props.initialQuery,
  });

  const [search, setSearch] = React.useState<string>();
  const [searchValue] = useDebounce(search, 500);

  const fetchList = async () => {
    try {
      setIsLoading(true);

      const res = await httpRequest.get<
        IHttpResponse<IPayloadPagination<DataType>>
      >(`${props.endpoint}${generateQueryString(query)}`);

      console.log("FETCHLIST RES", res);

      if (res && res.data && res.data.payload) {
        setPagination((oldVal) => {
          return {
            ...oldVal,
            perPage: props.limit || DEFAULT_LIMIT,
            prev: res.data.payload.prev,
            next: res.data.payload.next,
            totalData: res.data.payload.count,
            countPage: Math.ceil(
              res.data.payload.count / (props.limit || DEFAULT_LIMIT)
            ),
          };
        });

        if (props.pushData) {
          let list = [...data, ...res.data.payload.results];
          // const list = _.union(data, res.data.payload.results)
          if (props.columnId) {
            //only applied for menus FE
            list = _.uniqBy(list, props.columnId);
          }
          setData(list);
        } else {
          setData(res.data.payload.results);
        }
        setIsLoading(false);
      } else {
        setIsLoading(false);
        message.error("Failed to get data.");
      }
    } catch (error) {
      message.error("Failed to get data. " + getErrorMessage(error));
      setIsLoading(false);
    }
  };

  React.useEffect(() => {
    fetchList();
  }, [query]);

  React.useEffect(() => {
    setData([]);
    setQuery((e: IFetchQuery) => {
      return { ...e, search: searchValue as string };
    });
  }, [searchValue]);

  const changePage = (page: any) => {
    setPagination((oldVal) => {
      return {
        ...oldVal,
        page,
      };
    });
    setQuery((oldVal) => {
      return {
        ...oldVal,
        offset: (page - 1) * (props.limit || DEFAULT_LIMIT),
      };
    });
  };

  const setQueryAndResetData = (newQuery: IFetchQuery) => {
    setData([]);
    setQuery((oldVal) => ({ ...oldVal, ...newQuery }));
  };

  const resetQueryAndResetData = (newQuery?: IFetchQuery) => {
    setData([]);
    setQuery({
      ...INITIAL_QUERY,
      limit: props.limit || DEFAULT_LIMIT,
      ...props.initialQuery,
      ...newQuery,
    });
  };

  return {
    isLoading,
    data,
    pagination,
    query,
    setData,
    setPagination,
    setQuery,
    setQueryAndResetData,
    setSearch,
    resetQueryAndResetData,
    changePage,
    fetchList,
  };
}
