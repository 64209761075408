import { format } from 'date-fns';

const date = new Date();

export const now = format(new Date(), 'MMMM dd, yyyy');
export const appVersion: string | undefined =
  process.env.REACT_APP_VERSION_NAME;
export const thisYear = date.getFullYear();

export const getInitialName = (name: string) => {
  const split: string[] = name.split(' ');

  let start = 0;
  let initial = [];

  if (split.length > 1) {
    while (start <= 1) {
      initial.push(split[start].charAt(0));
      start++;
    }
  } else {
    initial.push(split[0].substr(0, 2));
  }

  return initial.join('').toUpperCase();
};

export const formatDate = (date: any) => {
  if (date) {
    const formated = format(new Date(date), 'MMMM dd, yyyy');
    return formated;
  }
  return '';
};

export const formatUrlParams = (value: string) => {
  const param = value.split(' ').join('-').toLowerCase();
  return param;
};

export const SHORT_DAYS_IN_WEEK = [
  'Sun',
  'Mon',
  'Tue',
  'Wed',
  'Thu',
  'Fri',
  'Sat',
];

export const prototypeLinks = [
  {
    title: 'Berca Mobile App',
    link: 'https://prototype.qbit.co.id/berca-mobile',
  },
  {
    title: 'Berca CMS',
    link: 'https://prototype.qbit.co.id/berca-cms',
  },
  {
    title: 'Kestingrum Mobile Talent',
    link: 'https://prototype.qbit.co.id/kestingrum-mobile-talent-v1',
  },
  {
    title: 'Kestingrum Mobile Recruiter',
    link: 'https://prototype.qbit.co.id/kestingrum-mobile-recruiter-v1',
  },
  {
    title: 'Kestingrum Web',
    link: 'https://prototype.qbit.co.id/kestingrum-web',
  },
  {
    title: 'Kestingrum CMS',
    link: 'https://prototype.qbit.co.id/kestingrum-cms',
  },
  {
    title: 'Kestingrum CMS',
    link: 'https://prototype.qbit.co.id/kestingrum-cms',
  },
  {
    title: 'Ramus FMS',
    link: 'https://www.figma.com/proto/nahkMhRODTTWPyKEQHjNe2/Ramus-Web-App-v2.0?page-id=4%3A5895&type=design&node-id=264-9443&viewport=311%2C-56%2C0.07&t=7suoSGudmjZoZC0n-1&scaling=min-zoom&starting-point-node-id=264%3A9443&show-proto-sidebar=1&hide-ui=1',
  },
  {
    title: 'Ramus - Marinesat',
    link: 'https://www.figma.com/proto/nahkMhRODTTWPyKEQHjNe2/Ramus-Web-App-v2.0?node-id=3135-24611&t=Es2Oi28uZfsjIsoc-0&scaling=scale-down-width&content-scaling=fixed&page-id=2616%3A25290&starting-point-node-id=3135%3A24611&show-proto-sidebar=1&hide-ui=1',
  },
  {
    title: 'Ramus - Samudera',
    link: 'https://www.figma.com/proto/nahkMhRODTTWPyKEQHjNe2/Ramus-Web-App-v2.0?node-id=2616-25376&t=Es2Oi28uZfsjIsoc-0&scaling=scale-down-width&content-scaling=fixed&page-id=2616%3A25290&starting-point-node-id=2616%3A25376&show-proto-sidebar=1&hide-ui=1',
  },
  {
    title: 'Ramus - Telkomsat',
    link: 'https://www.figma.com/proto/nahkMhRODTTWPyKEQHjNe2/Ramus-Web-App-v2.0?node-id=3127-25767&t=Es2Oi28uZfsjIsoc-0&scaling=scale-down-width&content-scaling=fixed&page-id=2616%3A25290&starting-point-node-id=3127%3A25767&show-proto-sidebar=1&hide-ui=1',
  },
  {
    title: 'Ramus - Pinisi Telkomsat',
    link: 'https://www.figma.com/proto/2aJYm7jCuznxnHBo8uZfeK/Ramus-Web-App-v2.0---Pinisi---Telkomsat?node-id=264-9443&node-type=section&t=x9SOtnDuhvQNNSIR-8&scaling=scale-down-width&content-scaling=fixed&page-id=4%3A5895&starting-point-node-id=264%3A9443&hide-ui=1',
  },
  {
    title: 'Invest Mobile Alternative 1',
    link: 'https://prototype.qbit.co.id/invest-mobile-client-1',
  },
  {
    title: 'Invest Mobile Alternative 2',
    link: 'https://prototype.qbit.co.id/invest-mobile-client-2',
  },
  {
    title: 'UPH Mobile Alternative 1',
    link: 'https://prototype.qbit.co.id/uph-mobile-1',
  },
  {
    title: 'UPH Mobile Alternative 2',
    link: 'https://prototype.qbit.co.id/uph-mobile-2',
  },
  {
    title: 'Nusantara Mobile',
    link: 'https://prototype.qbit.co.id/nusantara-mobile',
  },
  {
    title: 'PelicanX CMS',
    link: 'https://prototype.qbit.co.id/pelicanx-cms',
  },
  {
    title: 'PelicanX Mobile Responsive',
    link: 'https://prototype.qbit.co.id/pelicanx-mobile-responsive',
  },
  {
    title: 'Pahala Mobile Responsive',
    link: 'https://prototype.qbit.co.id/pahalaexpress-mobile-responsive',
  },
  {
    title: 'Pahala CMS',
    link: 'https://prototype.qbit.co.id/pahalaexpress-cms',
  },
  {
    title: 'Polri Web',
    link: 'https://prototype.qbit.co.id/polri-web',
  },
  {
    title: 'Polri Mobile',
    link: 'https://prototype.qbit.co.id/polri-mobile',
  },
  {
    title: 'Zeppelin Web',
    link: 'https://prototype.qbit.co.id/zeppelin-web',
  },
  {
    title: 'Mi Kitchen CMS',
    link: 'https://prototype.qbit.co.id/mikitchen-cms',
  },
  {
    title: 'Mi Kitchen Mobile Responsive',
    link: 'https://prototype.qbit.co.id/mikitchen-mobile-responsive',
  },
  {
    title: 'MIS Mobile',
    link: 'https://prototype.qbit.co.id/mis-mobile',
  },
  {
    title: 'MIS Web',
    link: 'https://prototype.qbit.co.id/mis-web',
  },
  {
    title: 'JTM Mobile',
    link: 'https://prototype.qbit.co.id/jtm-mobile',
  },
  {
    title: 'Matra TV Mobile',
    link: 'https://prototype.qbit.co.id/matratv-mobile',
  },
  {
    title: 'Matra TV Web',
    link: 'https://prototype.qbit.co.id/matratv-web',
  },
  {
    title: 'Koleq',
    link: 'https://prototype.qbit.co.id/koleq-mobile',
  },
];
