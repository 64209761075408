import { useMemo } from "react";
// import { Col } from "antd";
import { ProjectProps } from "../../types/project.type";
import isDarkColor from "is-dark-color";
import { Image } from "antd";

type Props = {
  isMobile: boolean;
  item: ProjectProps;
  onClick: () => any;
};

export default function ProjectItem({ isMobile, item, onClick }: Props) {
  const textColor = useMemo(
    () => (isDarkColor("#" + item.backgroundColor) ? "#ffffff" : "#3a3a3a"),
    [item]
  );
  return (
    // <Col xs={12} md={8} className="project-item">
    <div className="project-item">
      <div
        className="content"
        style={{
          backgroundColor: "#" + item.backgroundColor,
          // height: !isMobile ? "613px" : "224px", // Klo mau responsive, ganti 613px jadi 42vw
        }}
        onClick={onClick}
      >
        <div className="title" style={{ color: textColor }}>
          {item.title}
        </div>
        <div className="subtitle" style={{ color: textColor }}>
          {item.subtitle}
        </div>
        <div className="img-container">
          <Image
            src={item.coverImage?.imageUrl}
            height="inherit"
            preview={false}
          />
        </div>
      </div>
      {/* </Col> */}
    </div>
  );
}
