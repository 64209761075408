import { ProjectProps } from "../../types/project.type";
import isDarkColor from "is-dark-color";
import HTMLPreview from "../HTMLPreview";
import { Link } from "react-router-dom";
import { useMemo } from "react";

export const carouselSettings = {
  autoplay: true,
  slidesToShow: 1,
  infinite: true,
  autoplaySpeed: 4000,
  rows: 1,
  slidesPerRow: 1,
  draggable: true,
  fade: true,
  dots: false,
};

interface IProps {
  item: ProjectProps;
  index: number;
  screenWidth: number;
}

export default function ItemProjects({ item, index, screenWidth }: IProps) {
  const textColor = isDarkColor("#" + item?.backgroundColor)
    ? "white"
    : "black";

  const platformTagNames = useMemo(() => {
    if (!item?.platformTags) return;

    const tags = item.platformTags
      .slice(0, 2)
      .map((tag) => tag.platformTagName);
    if (item.platformTags.length > 2) {
      tags.push("+" + (item.platformTags.length - 2) + " More");
    }

    return tags;
  }, [item]);

  return (
    <div
      className={`item-container ${
        index === 0 || index === 4 ? "item-container-large" : ""
      }`}
      style={{
        order:
          screenWidth <= 1200
            ? index === 3
              ? 4
              : index === 4
              ? 3
              : index
            : index,
      }}
    >
      {/* <Carousel {...carouselSettings}>
        {item?.galleryImages?.map((image: any, index: number) => (
          <img
            key={index}
            className="carousel-img"
            src={image.imageUrl}
            alt="qbit-project"
          />
        ))}
      </Carousel> */}
      <img
        key={index}
        className="carousel-img"
        src={
          item.galleryImages && item.galleryImages.length > 0
            ? item.galleryImages[
                Math.floor(Math.random() * (item.galleryImages.length - 1))
              ].imageUrl
            : ""
        }
        alt="qbit-project"
      />
      <div className="item-overlay">
        <div
          className={`bottom-info ${
            index === 0 || index === 4 ? "bottom-info-large" : ""
          }`}
        >
          <div className="item-info">
            <h4>{item.title}</h4>
            <HTMLPreview html={item.description} className="item-info-desc" />
            <div className="platforms">
              {(index === 0 || index === 4) &&
                platformTagNames?.map((platformTagName, index) => (
                  <div key={index} className="platform">
                    {platformTagName}
                  </div>
                ))}
            </div>
          </div>
          <div className="btn-platform-container">
            <div className="platforms">
              {index !== 0 &&
                index !== 4 &&
                platformTagNames?.map((platformTagName, index) => {
                  if (index === 0 || index === platformTagNames.length - 1) {
                    return (
                      <div key={index} className="platform">
                        {index > 0 && index === platformTagNames.length - 1
                          ? "+" + (platformTagNames.length - 1) + " More"
                          : platformTagName}
                      </div>
                    );
                  }
                })}
            </div>
            <Link
              to={item.projectId ? `/projects/${item.projectId}` : ""}
              className="detail-button"
            >
              View Detail
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
