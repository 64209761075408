import React from 'react';
import RootNavigator from './navigation/RootNavigator';
import './assets/app.less';
import { theme } from './assets/theme';
import { ThemeProvider } from 'styled-components';

import ReactGA from 'react-ga4';
ReactGA.initialize('G-252JVP2SVM');

function App() {
  return (
    <ThemeProvider theme={theme}>
      <RootNavigator />
    </ThemeProvider>
  );
}

export default App;
