import { Footer } from "antd/lib/layout/layout";
import useWindowSize from "../hooks/useWindowSize";
import {
  imgLogo,
  iconEmail,
  iconTelephone,
  iconLinkedIn,
  iconInstagram,
} from "../assets/images";
import useGlobalConfig from "../hooks/useGlobalConfig";
import { Link } from "react-router-dom";
import { Fragment } from "react";


export default function AppFooter() {
  const { width } = useWindowSize();
  const { aboutUs } = useGlobalConfig();
  // const {
  //   isLoading,
  //   data,
  //   pagination,
  //   query,
  //   setData,
  //   setSearch,
  //   setQuery,
  //   setQueryAndResetData,
  //   changePage
  // } = useFetchList<{
  //   aboutusId: string,
  //   email: string,
  //   emailCareer: string,
  //   contact: string,
  //   instagramLink: string,
  //   linkedinLink: string,
  //   companyDescription: string;
  //   visitUsDescription: string
  // }>({
  //   endpoint: 'homepages',
  //   limit: 6,
  //   initialQuery: {
  //       filterStatus: 'active',
  //   },
  // });

  // useEffect(() => {
  //   console.log(data)
  // }, [data])

  return (
    <Footer className="footer-container">
      <div className="footer-layout-content">
        <div className="section-left">
          <img src={imgLogo} alt="qbit logo" />
          <h3 className="title">PT. Vanz Inovatif Teknologi</h3>
          <p className="desc">
            <div dangerouslySetInnerHTML={{__html:aboutUs.companyDescription || ''}}></div>
          </p>
          <h5 className="visit-us-on">Visit us on</h5>
          <p className="desc">
          <div dangerouslySetInnerHTML={{__html:aboutUs.visitUsDescription || ''}}></div>
          </p>
        </div>
        <div className="section-center">
          <h5 className="section-head">Menu</h5>
          <div className="footer-nav">
            <Link to="/" className="menu-link">
              Home
            </Link>
            <Link to="/#services" className="menu-link">
              Our Services
            </Link>
            <Link to="/#clients" className="menu-link">
              Clients
            </Link>
            <Link to="/career" className="menu-link">
              Career
            </Link>
            <Link to="/about-us" className="menu-link">
              About Us
            </Link>
            <Link to="/#get-in-touch" className="menu-link">
              Get in Touch
            </Link>
          </div>
        </div>
        <div className="section-right">
          <div>
            <h5 className="section-head">Contact</h5>
            <a href={`mailto:${aboutUs.email}`} className="item-container">
              <img src={iconEmail} alt="Email icon" className="icon" />
              <span>
                &#99;o&#110;t&#97;ct&#117;&#115;&#64;q&#98;it&#46;co&#46;id
              </span>
            </a>
            <a href="tel:+62-815-8853-286" className="item-container">
              <img src={iconTelephone} alt="Telephone icon" className="icon" />
              <span>{aboutUs.contact}</span>
            </a>
          </div>
          <div>
            <h5 className="section-head">Follow us</h5>
            <div className="items-container">
              <a
                href={aboutUs.linkedinLink}
                className="item-container"
              >
                <img
                  src={iconLinkedIn}
                  alt="LinkedIn icon"
                  className="icon icon-large"
                />
              </a>
              <a
                href={aboutUs.instagramLink}
                className="item-container"
              >
                <img
                  src={iconInstagram}
                  alt="Instagram icon"
                  className="icon icon-large"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </Footer>
  );
}
