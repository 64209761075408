import React from 'react';
import AppLayout from '../layout/AppLayout';
import { Card, Form, Input, message, Typography } from 'antd';
import { generateFormRules } from '../helpers/formRules';
import { CheckCircleOutlined, LockOutlined } from '@ant-design/icons';
import AppButton from '../components/AppButton';
import { getErrorMessage } from '../helpers/errorHandler';
import { useParams } from 'react-router-dom';
import { IHttpResponse } from '../helpers/pagination';
import axios from 'axios';
import useModalAuth from '../hooks/useModalAuth';
import useWindowSize from '../hooks/useWindowSize';

const {Title, Text} = Typography;

interface URLProps {
  sessionId: string;
}

interface ResetPasswordProps {
  newPassword: string;
  retypePassword: string;
}

const ResetPassword: React.FC = () => {
  const {isMobile} = useWindowSize()
  const { sessionId } = useParams<URLProps>();

  const [isShowSuccessMessage, setIsShowSuccessMessage] = React.useState(false)
  const [isLoading, setIsLoading] = React.useState(false)

  const onSubmit = (values: ResetPasswordProps) => {
    console.info('values', JSON.stringify(values))
    submit(values)
  }

  const submit = async (values: ResetPasswordProps) => {
    if (values.newPassword !== values.retypePassword) {
      message.error('Password and Confirm Password is different');
      return;
    }

    try {
      setIsLoading(true);

      await axios.post<IHttpResponse<any>>(
        '/auth/change-password/session',
        { newPassword: values.newPassword, sessionId }
      );

      setIsShowSuccessMessage(true);
      setIsLoading(false);
    } catch (err) {
      message.error(getErrorMessage(err));
      setIsLoading(false);
    }
  }

  return (
    <AppLayout>
      {isShowSuccessMessage ? (
        <ResetPasswordSuccess
          onPressTryAgain={() => setIsShowSuccessMessage(false)}
        />
      ) : sessionId ? (
        <div style={{ textAlign: '-webkit-center' as any }}>
          <Card style={{ width: isMobile ? undefined : 500, textAlign: 'center' }} bordered={false}>
            <Title>Reset Password</Title>
            <Text>Enter the new password that you will use to sign in</Text>

            <Form
              // ref={formRef}
              layout="vertical"
              name="basic"
              initialValues={{
                newPassword: '',
                retypePassword: ''
              }}
              onFinish={onSubmit}
              style={{ marginTop: 30 }}
            >
              <Form.Item
                name="newPassword"
                label="New Password"
                rules={generateFormRules('New Password', [
                  'required',
                  'password',
                ])}
              >
                <Input.Password
                  size="large"
                  type="password"
                  prefix={<LockOutlined />}
                  placeholder="Enter your new password"
                />
              </Form.Item>

              <Form.Item
                name="retypePassword"
                label="Confirm Password"
                rules={generateFormRules('Confirm Password', [
                  'required',
                  'password',
                ])}
              >
                <Input.Password
                  size="large"
                  type="retypePassword"
                  prefix={<LockOutlined />}
                  placeholder="Enter your confirm password"
                />
              </Form.Item>

              <Form.Item>
                <AppButton
                  loading={isLoading}
                  size="large"
                  type="primary"
                  htmlType="submit"
                  style={{ width: '100%' }}
                >
                  Reset Password
                </AppButton>
              </Form.Item>
            </Form>
          </Card>
        </div>
      ) : (
        <div style={{textAlign: 'center'}}>Invalid Session</div>
      )}
    </AppLayout>
  );
};

const ResetPasswordSuccess = (props: { onPressTryAgain: () => void }) => {
  const {isMobile} = useWindowSize()
  const { setIsShowModalLogin } = useModalAuth();
  
  return (
    <div style={{ textAlign: '-webkit-center' as any }}>
      <Card
        style={{ width: isMobile ? undefined : 500, textAlign: 'center' }}
        bordered={false}
      >
        <div
          style={{
            margin: '0 auto',
            background: 'lightgreen',
            width: 50,
            justifyContent: 'center',
            paddingTop: 10,
            paddingBottom: 10,
            borderRadius: 5,
          }}
        >
          <CheckCircleOutlined style={{ fontSize: 20, color: 'green' }} />
        </div>

        <Title>Sucessfully Reset Password</Title>
        <Text>
          Your password has been successfully reset, now you can sign in with
          your new password
        </Text>

        <div style={{ marginTop: 50 }}>
          <AppButton
            size="large"
            type="primary"
            htmlType="submit"
            style={{ width: '100%' }}
            onClick={() => {
              setIsShowModalLogin(true);
            }}
          >
            Sign In Now
          </AppButton>
        </div>
      </Card>
    </div>
  );
}

export default ResetPassword;
