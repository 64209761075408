interface IProps {
  title?: string;
  subtitle?: string;
  desc?: string;
  classCustom?: any;
}

export default function IntroBanner({
  title,
  subtitle,
  desc,
  classCustom,
}: IProps) {
  return (
    <div className={`intro-banner ${classCustom || ""}`}>
      <div className="content site-layout-content">
        <h4>{subtitle}</h4>
        <h3>{title}</h3>
        <p>{desc}</p>
      </div>
    </div>
  );
}
