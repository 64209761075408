import React, { useMemo } from "react";
import { HeaderContent } from "../";
import useFetchList from "../../hooks/useFetchList";

const Services = React.forwardRef<HTMLInputElement>((props, ref) => {
  const { data } = useFetchList<{
    title: string;
    content: string;
    icon: string;
    specializes: string[];
  }>({
    endpoint: "homepages",
    limit: 6,
    initialQuery: {
      isPublished: true,
    },
  });

  const services = useMemo(
    () =>
      data.map((d) => ({
        ...d,
        content: d.content
          .split(",")
          .map((content) =>
            content.trim().replaceAll("<p>", "").replaceAll("</p>", "")
          ),
      })),
    [data]
  );

  return (
    <div ref={ref} id="services" className="services-home site-layout-content">
      <HeaderContent
        title="Our Services"
        subtitle="Profesional quality of IT solution and services provide by a highly
          qualified and motivated team"
      />
      <div className="content">
        {services?.map((d) => (
          <div className="item-card">
            <div className="item-icon-container">
              <img
                width={76}
                height={76}
                src={`/icons/service-${d.icon}.svg`}
                alt={`${d.title} icon`}
                className="item-icon"
              />
            </div>
            <h3 className="item-title">{d.title}</h3>
            {/* <p className="item-experiences-title">Experience in:</p> */}
            <ul className="item-experiences-list">
              {d.content?.map((specialize, index) => (
                <li key={index}>{specialize}</li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </div>
  );
});

export default Services;
