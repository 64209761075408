import { Col, Row } from 'antd'
import React from 'react'
import { SearchOutlined } from '@ant-design/icons';
import Title from 'antd/lib/typography/Title';

export default function EmptyData() {
  return (
    <Row style={{marginBottom: 20}}>
      <Col span={24} style={{ textAlign: 'center', padding: 50 }}>
        <div style={{padding: 20}}>
          <SearchOutlined style={{ fontSize: 40, color: 'darkgrey' }} />
        </div>
        <Title level={4} style={{ color: 'darkgrey' }}>
          Oops. There is nothing here...
        </Title>
      </Col>
    </Row>
  );
}