import { createGlobalState } from 'react-hooks-global-state';

const initialState = { isShowModalLogin: false, isShowModalRegister: false };
const { useGlobalState } = createGlobalState(initialState);

export default function useModalAuth() {
  const [isShowModalLogin, setIsShowModalLogin] =
    useGlobalState('isShowModalLogin');
  const [isShowModalRegister, setIsShowModalRegister] =
    useGlobalState('isShowModalRegister');
  
  return {
    isShowModalLogin,
    setIsShowModalLogin,
    isShowModalRegister,
    setIsShowModalRegister,
  };
}