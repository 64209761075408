import AppLayout from '../../layout/AppLayout'
import { Image, Select, DatePicker, Row, Col, Typography, Tag, Card, Divider, Dropdown, Menu, Button } from 'antd';
import { httpRequest } from '../../helpers/api';
import { ETransactionStatus, TransactionProps } from '../../types/transaction.type';
import React from 'react';
import { generateQueryString } from '../../helpers/generateQueryString';
import { IHttpResponse, IPayloadPagination } from '../../helpers/pagination';
import moment from 'moment';
import useWindowSize from '../../hooks/useWindowSize';
import EmptyData from '../../components/EmptyData';
import { DownOutlined } from '@ant-design/icons';
import poliLogo from '../../assets/images/poli-payments-logo.png'
import ModalOrderCreated from '../../components/ModalOrderCreated';
import useAuth from '../../hooks/useAuth';

const { Text, Title } = Typography
interface QueryProps {
  limit: number
  transactionStatus: string
  scheduledAtFrom: string
  scheduledAtUntil: string
  sortBy: string
  sortOrder: string
}

enum enumPaymentMethod {
  BANK_TRANSFER = 'BANK_TRANSFER',
  EWALLET = 'EWALLET',
  OUTLET = 'OUTLET',
  POLI_LINK = 'POLI_LINK',
}

enum enumPaymentType {
  PAYMENT_GATEWAY = 'PAYMENT_GATEWAY',
  CASH = 'CASH'
}

enum enumPaymentStatus {
  WAITING_PAYMENT = 'WAITING_PAYMENT',
  PAID_FULL = 'PAID_FULL',
  CANCELLED_BY_SYSTEM = 'CANCELED_BY_SYSTEM'
}

enum enumPaymentVendorId {
  BNI = 'bni',
  BRI = 'bri',
  MANDIRI = 'mandiri',
  GOPAY = 'gopay',
  OVO = 'ovo',
  DANA = 'dana',
  INDOMARET = 'indomaret',
  ALFAMART = 'alfamart',
  WESTPAC = 'WESTPAC',
  ANZ = 'ANZ',
  COMMONWEALTH = 'COMMONWEALTH',
}

const MyOrders = () => {
  const { isMobile } = useWindowSize()
  const { Option } = Select
  const [transactions, setTransactions] = React.useState<Array<TransactionProps>>([])
  const [query, setQuery] = React.useState<QueryProps>({
    limit: 0,
    transactionStatus: '',
    scheduledAtFrom: '',
    scheduledAtUntil: '',
    sortBy: 'createdAt',
    sortOrder: 'DESC'
  })
  const [isLoading, setIsLoading] = React.useState<boolean>(false)
  const [isShowModalOrderCreated, setIsShowModalOrderCreated] = React.useState(false);
  const [imageUrl, setImageUrl] = React.useState<string | undefined>();

  const { user } = useAuth()

  React.useEffect(() => {
    const fetchTransaction = async () => {
      try {
        setIsLoading(true)
        const res = await httpRequest.get<IHttpResponse<IPayloadPagination<TransactionProps>>>(`/transactions${generateQueryString(query)}`)
        setTransactions(res.data.payload.results)
        setIsLoading(false)
      } catch (err) {
        setIsLoading(false)
      }
    }
    fetchTransaction()
  }, [query])

  const handleChangeStatus = (transactionStatus: string) => {
    if (transactionStatus !== 'all') {
      if (transactionStatus === 'CANCELLED') {
        setQuery((oldVal) => ({ ...oldVal, transactionStatus: 'CANCELED_BY_BUYER,CANCELED_BY_ADMIN,CANCELED_BY_SYSTEM' }))
      } else {
        setQuery((oldVal) => ({ ...oldVal, transactionStatus }))
      }
    } else {
      setQuery((oldVal) => ({ ...oldVal, transactionStatus: '' }))
    }
  }

  const handleUpdatePayment = async (props: TransactionProps, key: string) => {
    setIsShowModalOrderCreated(true)
    if (key === 'payment') {
      await httpRequest.post(`/payments/initiate/payment`, {
        transactionId: props.transactionId
      })
    }
    else {
      await httpRequest.patch(`/transactions/${props.transactionId}/cancel`)
    }

  }

  return (
    <AppLayout hasSidebar>
      <Card>
        <Row gutter={[8, 8]} justify="space-between" wrap>
          <Col xs={12} md={12} lg={8}>
            <Title level={2}>My Orders</Title>
          </Col>
          <Col xs={8} md={8} lg={8} style={{ textAlign: 'right' }}>
            <Select
              size="large"
              style={{ borderRadius: 100, width: 120, textAlign: 'left' }}
              onChange={handleChangeStatus}
              placeholder="Status"
            >
              <Option value="all">All</Option>
              <Option value="CREATED">Created</Option>
              <Option value="PAID">Paid</Option>
              <Option value="CANCELLED">Cancelled</Option>
              <Option value="COMPLETED">Done</Option>
            </Select>
          </Col>

          <Col xs={24} md={24} lg={8}>
            <DatePicker.RangePicker
              style={{
                width: '100%',
                borderRadius: '100px',
              }}
              size="large"
              format="MM / DD / YYYY"
              order={false}
              onChange={(_, formatString: [string, string]) =>
                setQuery({
                  ...query,
                  scheduledAtFrom: moment.utc(formatString[0])
                    .startOf('day')
                    .toISOString(),
                  scheduledAtUntil: moment.utc(formatString[1])
                    .endOf('day')
                    .toISOString(),
                })
              }
            />
          </Col>
        </Row>
      </Card>
      <br></br>

      <Card>
        {transactions.length === 0 && (
          <EmptyData />
        )}
        {transactions.map((transaction) => {
          return (
            <>
              <Row justify="space-between" align="middle">
                <Col lg={18} xs={12}>
                  <Row>
                    <Text
                      style={{
                        fontWeight: '600',
                        fontSize: '16px',
                        lineHeight: '24px',
                      }}
                    >
                      {moment(
                        transaction.shipment.scheduledAt,
                        'YYYY-MM-DD'
                      ).format('dddd, MMM D YYYY')}
                    </Text>
                  </Row>
                  <Row>
                    <Col lg={5} xs={20}>
                      <Text>
                        {transaction.shipment.meta.pickupTimeStart} -{' '}
                        {transaction.shipment.meta.pickupTimeEnd}
                      </Text>
                    </Col>
                    {!isMobile && (
                      <Col lg={19} xs={20}>
                        <Tag
                          color="default"
                          style={{ borderRadius: '100px' }}
                        >
                          {transaction.shipment.method}
                        </Tag>
                        <Text type="secondary">Pickup Point</Text>
                        <Text style={{ marginLeft: '2%' }}>
                          - {transaction.shipment.meta.pickupFromLocationName}
                        </Text>
                      </Col>
                    )}
                  </Row>
                </Col>
                <Col>
                  {
                    transaction.transactionStatus === ETransactionStatus.CREATED &&
                    <Button
                      style={{
                        borderRadius: 20,
                        backgroundColor: 'white',
                        border: '1px solid black',
                        color: 'black',
                        height: '2em',
                        width: '8.5em',
                      }}
                      onClick={() => handleUpdatePayment(transaction, 'payment')}
                    >
                      {<Image
                        src={poliLogo}
                        preview={false}
                        style={{ paddingRight: '3%'}}
                      />}
                      <Text style={{ paddingLeft: '5%'}}>Pay Now</Text>
                    </Button>
                  }
                </Col>
                <Col>
                  {/* <Text
                    style={{
                      fontWeight: '400',
                      fontSize: '14px',
                      borderRadius: '100px',
                      lineHeight: '150%',
                      padding: '10px',
                      backgroundColor:
                        transaction.transactionStatus ===
                          ETransactionStatus.CREATED
                          ? '#FFF9EF'
                          : transaction.transactionStatus ===
                            ETransactionStatus.COMPLETED
                            ? '#F5FFF9'
                            : '#F3FBFF',
                      color:
                        transaction.transactionStatus ===
                          ETransactionStatus.CREATED
                          ? '#E89635'
                          : transaction.transactionStatus ===
                            ETransactionStatus.COMPLETED
                            ? '#39AC6E'
                            : '#1292EE',
                      borderColor:
                        transaction.transactionStatus ===
                          ETransactionStatus.CREATED
                          ? '#E89635'
                          : transaction.transactionStatus ===
                            ETransactionStatus.COMPLETED
                            ? '#39AC6E'
                            : '#1292EE',
                    }}
                  >
                    {transaction.transactionStatus === ETransactionStatus.COMPLETED ? 'DONE' : transaction.transactionStatus}
                  </Text> */}
                  <Dropdown overlay={
                    <Menu onClick={(e) => handleUpdatePayment(transaction, e.key)} disabled={transaction.transactionStatus !== ETransactionStatus.CREATED}>
                      <Menu.Item key={'cancel'}>
                        Cancel
                      </Menu.Item>
                    </Menu>
                  }
                    trigger={['click']}>
                    <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                      <Tag style={{
                        backgroundColor:
                          transaction.transactionStatus === ETransactionStatus.CREATED
                            ? '#FFF9EF' :
                            transaction.transactionStatus === ETransactionStatus.COMPLETED
                              ? '#F5FFF9' : transaction.transactionStatus === ETransactionStatus.PAID ? '#F3FBFF' : '#FFF9FB',

                        color: transaction.transactionStatus === ETransactionStatus.CREATED
                          ? '#E89635' :
                          transaction.transactionStatus === ETransactionStatus.COMPLETED ? '#39AC6E' :
                            transaction.transactionStatus === ETransactionStatus.PAID ? '#1292EE' : '#0B998E',

                        borderColor:
                          transaction.transactionStatus === ETransactionStatus.CREATED ? '#E89635' :
                            transaction.transactionStatus === ETransactionStatus.COMPLETED ? '#39AC6E' :
                              transaction.transactionStatus === ETransactionStatus.PAID ? '#1292EE' : '#0B998E',
                        borderRadius: '10px'
                      }}>
                        {transaction.transactionStatus === ETransactionStatus.COMPLETED
                          ? 'DONE'
                          : transaction.transactionStatus === ETransactionStatus.CANCELED_BY_BUYER ? 'CANCELLED' :
                            transaction.transactionStatus === ETransactionStatus.CANCELED_BY_ADMIN ? 'CANCELLED BY ADMIN' :
                              transaction.transactionStatus === ETransactionStatus.CANCELED_BY_SYSTEM ? 'CANCELLED BY SYSTEM' :
                                transaction.transactionStatus
                        }
                      </Tag>
                      <DownOutlined />
                    </a>
                  </Dropdown>
                </Col>
              </Row>

              <Divider style={{ marginTop: 10, marginBottom: 10 }} />

              {isMobile && (
                <Row align="middle">
                  <Tag color="default" style={{ borderRadius: '100px' }}>
                    {transaction.shipment.method}
                  </Tag>
                  <Text type="secondary">Pickup Point</Text>
                  <Text style={{ marginLeft: '2%' }}>
                    - {transaction.shipment.meta.pickupFromLocationName}
                  </Text>
                </Row>
              )}

              {transaction.items.map((menu) => {
                return (
                  <div>
                    <Row justify='space-between'>
                      <Col>
                        <Image
                          preview={false}
                          width={82}
                          height={48}
                          src={
                            (menu.metaProduct &&
                              menu.metaProduct.images &&
                              menu.metaProduct.images.length > 0
                              ? menu.metaProduct?.images[0].imageUrl
                              : '/images/select-image.jpg') ||
                            '/images/select-image.jpg'
                          }
                          fallback={'/images/blur-image.jpeg'}
                        />
                      </Col>
                      <Col xs={1}>
                        <Text
                          strong
                          style={{
                            fontWeight: '600',
                            fontSize: '16px',
                          }}
                        >
                          {menu.quantity}x
                        </Text>
                      </Col>
                      <Col style={{ textAlignLast: 'right' }} xs={2}>
                        <Text
                          strong
                          style={{
                            fontWeight: '600',
                            fontSize: '16px',
                          }}
                        >
                          ${menu.metaProduct.variant?.price.toFixed(2)}
                        </Text>
                      </Col>
                      <Col xs={12} style={{ paddingLeft: '2%' }}>
                        <Row>
                          <Text
                            style={{
                              fontWeight: '400',
                              fontSize: '20px',
                              lineHeight: '21px',
                            }}
                          >
                            {menu.metaProduct.name}
                          </Text>
                        </Row>
                        <Row style={{ marginTop: '2%' }}>
                          <Text
                            style={{
                              fontSize: '12px',
                              fontWeight: '400',
                              lineHeight: '21px',
                            }}
                          >
                            Variant {menu.metaProduct?.variant?.label}
                          </Text>
                        </Row>
                      </Col>
                      <Col style={{ textAlignLast: 'right' }} span={3}>
                        <Text
                          strong
                          style={{
                            fontSize: '16px',
                            fontWeight: '600',
                            lineHeight: '24px',
                          }}
                        >
                          ${(menu.productPrice * menu.quantity).toFixed(2)}
                        </Text>
                      </Col>
                    </Row>
                    <br></br>
                  </div>
                );
              })}
              <Divider />
            </>
          );
        })}
      </Card>

      <ModalOrderCreated
        {...{
          email: user.email,
          locationName: '',
          locationAddress: '',
          locationContact: '',
        }}
        visible={isShowModalOrderCreated}
        onClose={() => {
          setIsShowModalOrderCreated(false);
        }}
      />
    </AppLayout>

  );
}

export default MyOrders

// const CustomSelect = styled(Select)`
//   .ant-select-selector {
//     border-radius: 100px;
//   }
// `;