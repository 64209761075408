/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import AppLayout from '../layout/AppLayout';
import { Card, Form, FormInstance, Input, message, Typography } from 'antd';
import { generateFormRules } from '../helpers/formRules';
import { CheckCircleOutlined, LoadingOutlined, LockOutlined } from '@ant-design/icons';
import AppButton from '../components/AppButton';
import { getErrorMessage } from '../helpers/errorHandler';
import { useParams } from 'react-router-dom';
import { IHttpResponse } from '../helpers/pagination';
import axios from 'axios';
import useModalAuth from '../hooks/useModalAuth';

const {Title, Text} = Typography;

interface URLProps {
  sessionId: string;
}

interface VerifyEmailProps {
  newPassword: string;
  retypePassword: string;
}

const VerifyEmail: React.FC = () => {
  const { sessionId } = useParams<URLProps>();

  const [isShowSuccessMessage, setIsShowSuccessMessage] = React.useState(false)
  const [isLoading, setIsLoading] = React.useState(false)


  React.useEffect(() => {
    check();
  }, [])

  const check = async () => {
    try {
      setIsLoading(true);

      await axios.post<IHttpResponse<any>>(
        '/auth/verify-email',
        { sessionId }
      );

      setIsShowSuccessMessage(true);
      setIsLoading(false);
    } catch (err) {
      message.error(getErrorMessage(err));
      setIsLoading(false);
    }
  }

  return (
    <AppLayout>
      {isLoading ? (
        <div style={{ textAlign: 'center' }}>
          <LoadingOutlined style={{fontSize: 50}} />
        </div>
      ) : isShowSuccessMessage ? (
        <VerifyEmailSuccess />
      ) : (
        <div style={{ textAlign: 'center' }}>Invalid Session</div>
      )}
    </AppLayout>
  );
};

const VerifyEmailSuccess = () => {
  const { setIsShowModalLogin } = useModalAuth();
  
  return (
    <div style={{ textAlign: '-webkit-center' as any }}>
      <Card style={{ width: 500, textAlign: 'center' }} bordered={false}>
        <div
          style={{
            margin: '0 auto',
            background: 'lightgreen',
            width: 50,
            justifyContent: 'center',
            paddingTop: 10,
            paddingBottom: 10,
            borderRadius: 5,
          }}
        >
          <CheckCircleOutlined style={{ fontSize: 20, color: 'green' }} />
        </div>

        <Title>Email has been verified</Title>
        <Text>
          Your email has been successfully verified, now you can sign in and use full features.
        </Text>

        <div style={{ marginTop: 50 }}>
          <AppButton
            size="large"
            type="primary"
            htmlType="submit"
            style={{ width: '100%' }}
            onClick={() => {
              setIsShowModalLogin(true)
            }}
          >
            Sign In Now
          </AppButton>
        </div>
      </Card>
    </div>
  );
}

export default VerifyEmail;
