import React from "react";
import { CareerItem, SpinLoader } from "../";
import Masonry from "react-masonry-css";

interface IProps {
  isLoading?: boolean;
  data?: any[];
  handleClickCareer?: any;
}
const columnSettings = {
  default: 3,
  767: 1,
};
export default function CareerList({
  isLoading,
  data,
  handleClickCareer,
}: IProps) {
  return (
    <div className="career-list-container site-layout-content">
      <h4>Available Position</h4>
      {isLoading ? (
        <SpinLoader />
      ) : (
        <Masonry
          breakpointCols={columnSettings}
          className="career-list masonry-list"
          columnClassName="masonry-column"
        >
          {data?.map((item: any, index: number) => (
            <CareerItem
              key={index}
              item={item}
              index={index}
              handleClickCareer={handleClickCareer}
            />
          ))}
        </Masonry>
      )}
    </div>
  );
}
