import React from "react";

interface IProps {
  item?: any;
  index?: number;
}
export default function ItemGoals({ item, index }: IProps) {
  return (
    <div className="items-goals">
      <h3>{item?.title}</h3>
      <div className="img-container">
        <img src={item?.img} alt={`img-goals_${index}`} />
      </div>
      <div className="content-container">
        <h3>{item?.title}</h3>
        <div
          className="content"
          dangerouslySetInnerHTML={{ __html: item?.desc }}
        />
      </div>
    </div>
  );
}
