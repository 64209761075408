export type EAdminRole = 
  | 'admin' 
  | 'customer'
  | 'customer_premium'
  | 'customer_trainer'

export type EAdminStatus = 
  | 'active' 
  | 'inactive'

export interface IUser {
  userId: string;
  name: string;
  email: string;
  phone: string;
  userType: EAdminRole;
  status: EAdminStatus;
  updatedAt?: Date | string;
  createdAt?: Date | string;
  
  image?: File;
  profilePic?: string;
}


export const initialUser: IUser = {
  userId: '',
  name: '',
  email: '',
  phone: '',
  userType: 'customer',
  status: 'active',
};

export type IChangePassword = {
  oldPassword: '',
  newPassword: '',
  retypePassword: ''
}