import React from 'react';
import { httpRequest } from '../helpers/api';
import AppLayout from '../layout/AppLayout';
import { Col, message, Row, Typography, Space, Form, Input, FormInstance, Divider } from 'antd';
import { EnvironmentOutlined, FacebookOutlined, InstagramOutlined, MailOutlined, PhoneOutlined, TwitterOutlined } from '@ant-design/icons';
import { generateFormRules } from '../helpers/formRules';
import { IUserMessage } from '../types/customerMessage.type';
import TextArea from 'antd/lib/input/TextArea';
import AppButton from '../components/AppButton';
import AppMap from '../components/AppMap';
import useGlobalConfig from '../hooks/useGlobalConfig';
import { PROPS_MAPS } from '../helpers/map';
import useWindowSize from '../hooks/useWindowSize';

const {Title, Text} = Typography;

const ContactUs: React.FC = () => {
  const {isMobile} = useWindowSize()
  const {isLoadingAboutUs, aboutUs} = useGlobalConfig()
  
  const formRef =
    React.useRef<FormInstance<Omit<IUserMessage, 'createdAt' | 'updatedAt'>>>(
      null
    );

  const [isLoadingSubmit, setIsLoadingSubmit] = React.useState<boolean>(false)
  
  const handleSubmit = async (
    values: Omit<IUserMessage, 'createdAt' | 'updatedAt'>
  ) => {
    setIsLoadingSubmit(true)
    await httpRequest.post('customer-messages', values);
    message.success('Success')

    formRef.current?.resetFields();
    setIsLoadingSubmit(false)
  };

  return (
    <AppLayout
      showPageHeader={!isMobile}
      pageHeaderTitle={isMobile ? undefined :"Contact Us"}
      pageHeaderSubtitle={isMobile ? undefined :"Any question or remarks? Just write us message. Fill out the form below or send us an email!"}
    >
      <Row gutter={16}>
        <Col xs={24} md={11} lg={11}>
          {!isLoadingAboutUs && <AppMap height={250} 
          coords={[
            {...aboutUs?.officeCoords, isMain: true}
          ]} />}

          <div style={{marginTop: 20}}>
            <Title level={3}>{aboutUs?.officeTitle}</Title>
            <div style={{paddingTop: 5, paddingBottom: 5}}>
              <EnvironmentOutlined style={{ color: '#0B998E', fontSize: 18 }} />
              <span style={{color: 'grey', marginLeft: 5}}> {aboutUs?.officeAddress}</span>
            </div>
            <div style={{paddingTop: 5, paddingBottom: 5}}>
              <PhoneOutlined style={{ color: '#0B998E', fontSize: 18 }} />
              <span style={{color: 'grey', marginLeft: 5}}> {aboutUs?.contact}</span>
            </div>
            <div style={{paddingTop: 5, paddingBottom: 5}}>
              <MailOutlined style={{ color: '#0B998E', fontSize: 18 }} />
              <span style={{color: 'grey', marginLeft: 5}}> {aboutUs?.officeEmail}</span>
            </div>
          </div>
          
          <Space size="middle" style={{marginTop: 10}}>
            {!!aboutUs.instagramLink &&
            <a target="_blank" href={aboutUs.instagramLink}>
              <InstagramOutlined style={{ fontSize: 20, color: 'grey' }} />
            </a>}
            {!!aboutUs.twitterLink &&
            <a target="_blank" href={aboutUs.twitterLink}>
              <TwitterOutlined style={{ fontSize: 20, color: 'grey' }} />
            </a>}
            {!!aboutUs.facebookLink &&
            <a target="_blank" href={aboutUs.facebookLink}>
              <FacebookOutlined style={{ fontSize: 20, color: 'grey' }} />
            </a>}
            {/* {!!aboutUs.youtubeLink &&
            <a target="_blank" href={aboutUs.youtubeLink}>
              <YoutubeOutlined style={{ fontSize: 20, color: 'grey' }} />
            </a>} */}
          </Space>
        </Col>

        {isMobile ? (
          <div style={{padding: '0px 10px', textAlign: 'center', marginBottom: 20}}>
            <Divider />
            <Text style={{textAlign: 'center', fontSize: 14}}>
              Any question or remarks? Just write us message. Fill out the form below or send us an email!
            </Text>
          </div>
        ) : (
          <Col lg={2} style={{ textAlign: 'center' }}>
            <Divider
              type="vertical"
              style={{ borderLeftWidth: 1, height: '100%' }}
            />
          </Col>
        )}

        {/** FORM CONTACT US */}
        <Col xs={24} md={11} lg={11}>
          <Form
            ref={formRef}
            name="profileForm"
            layout="vertical"
            onFinish={handleSubmit}
          >
            <Form.Item
              label="Full Name"
              name="fullName"
              rules={generateFormRules('Full Name', ['required'])}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Email"
              name="email"
              rules={generateFormRules('Email', ['required', 'email'])}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Subject"
              name="subject"
              rules={generateFormRules('Subject', ['required'])}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Message"
              name="message"
              rules={generateFormRules('Name', ['required'])}
            >
              <TextArea />
            </Form.Item>
          </Form>

          <AppButton
            style={{ width: '100%' }}
            loading={isLoadingSubmit}
            type="primary"
            onClick={() => formRef?.current?.submit()}
          >
            Send Us Message
          </AppButton>
        </Col>
      </Row>
    </AppLayout>
  );
};

export default ContactUs;
