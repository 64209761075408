import React from "react";
import { Modal, Form, Input, Select } from "antd";
import TextArea from "antd/lib/input/TextArea";
import Text from "antd/lib/typography/Text";
import AppButton from "./AppButton";
import CheckableTag from "antd/lib/tag/CheckableTag";
import { generateFormRules } from "../helpers/formRules";

interface IProps {
  isModalVisible?: any;
  setIsModalVisible?: any;
  initialEmail?: any;
  setEmailData?: any;
  onClickSend?: any;
  servicesData?: any;
  emailData?: any;
  selectedTags?: any;
  setSelectedTags?: any;
}

export default function LetsTalkModal({
  isModalVisible,
  setIsModalVisible,
  initialEmail,
  setEmailData,
  onClickSend,
  servicesData,
  emailData,
  selectedTags,
  setSelectedTags,
}: IProps) {
  const { Option } = Select;
  return (
    <Modal
      className="modal-lets-talk"
      visible={isModalVisible}
      onCancel={() => {
        setIsModalVisible(false);
        setEmailData(initialEmail);
      }}
      onOk={onClickSend}
      footer={null}
      bodyStyle={{ padding: 0, borderRadius: "12px" }}
    >
      <div className="content">
        <h3>Let Us Know Your Project</h3>

        <Form layout="vertical" autoComplete="off">
          <Form.Item
            label="Name"
            name="name"
            rules={generateFormRules("Name & Company", ["required"])}
            className="form-input-container"
          >
            <Input
              placeholder="John Doe"
              value={emailData.name}
              onChange={(e) =>
                setEmailData({
                  ...emailData,
                  name: e.target.value,
                })
              }
            />
          </Form.Item>

          <Form.Item
            label="Company"
            name="company"
            rules={generateFormRules("Name & Company", ["required"])}
            className="form-input-container"
          >
            <Input
              placeholder="qbit"
              value={emailData.company}
              onChange={(e) =>
                setEmailData({
                  ...emailData,
                  company: e.target.value,
                })
              }
            />
          </Form.Item>

          <Form.Item
            label="Email"
            name="email"
            rules={generateFormRules("Email", ["required", "email"])}
            className="form-input-container"
          >
            <Input
              placeholder="johndoe@qbit.com"
              value={emailData.email}
              onChange={(e) =>
                setEmailData({
                  ...emailData,
                  email: e.target.value,
                })
              }
            />
          </Form.Item>

          <label className="label-input">Project Budget</label>
          <Select
            allowClear
            style={{ width: "100%" }}
            placeholder="50JT - 100JT"
            onChange={(e) => {
              setEmailData({
                ...emailData,
                projectBudget: e,
              });
            }}
            value={emailData.projectBudget}
          >
            {[
              <Option value="Unspecified">Unspecified</Option>,
              <Option value="50JT - 100JT">50JT - 100JT</Option>,
              <Option value="100JT - 500JT">100JT - 500JT</Option>,
              <Option value="500JT - 1M">500JT - 1M</Option>,
              <Option value="1M+">1M+</Option>,
            ]}
          </Select>

          <label className="label-input">Services</label>
          <div className="tag-services">
            {servicesData.map((service: any) => (
              <CheckableTag
                key={service.homepageId}
                onChange={() => {
                  if (selectedTags?.includes(service.homepageId as string)) {
                    setSelectedTags([
                      ...selectedTags?.filter(
                        (item: any) => item !== service.homepageId
                      ),
                    ]);
                  } else {
                    setSelectedTags([
                      ...selectedTags,
                      service.homepageId as string,
                    ]);
                  }
                }}
                checked={selectedTags?.includes(service.homepageId as string)}
              >
                <Text
                  style={{
                    color: selectedTags?.includes(service.homepageId as string)
                      ? "white"
                      : "grey",
                  }}
                >
                  {service.title}
                </Text>
              </CheckableTag>
            ))}
          </div>

          <Form.Item
            style={{
              paddingTop: "2%",
            }}
            label="Details Project"
            name="note"
            rules={generateFormRules("Details Project", ["required"])}
            className="form-input-container"
          >
            <TextArea
              value={emailData.note}
              placeholder="Hi, we need to build ..."
              onChange={(e) =>
                setEmailData({
                  ...emailData,
                  note: e.target.value,
                })
              }
              rows={4}
            />
          </Form.Item>

          <AppButton
            type="primary"
            style={{
              marginTop: "7%",
              minWidth: "100%",
            }}
            onClick={onClickSend}
          >
            Send
          </AppButton>
        </Form>
      </div>
    </Modal>
  );
}
