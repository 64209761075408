import { useEffect, useState, useRef, FC } from "react";
import {
  Form,
  Input,
  Layout,
  Row,
  message,
  Modal,
  FormInstance,
  Checkbox,
  Drawer,
  Divider,
  Col,
  Button,
} from "antd";
import {
  LockOutlined,
  MailOutlined,
  UserOutlined,
  PhoneOutlined,
  SearchOutlined,
  ShoppingCartOutlined,
} from "@ant-design/icons";
import PageHeader from "../components/PageHeader";
import { Link, useHistory, useLocation } from "react-router-dom";
import AppButton from "../components/AppButton";
import Text from "antd/lib/typography/Text";
import { httpRequest } from "../helpers/api";
import Title from "antd/lib/typography/Title";
import { generateFormRules } from "../helpers/formRules";
import { getErrorMessage } from "../helpers/errorHandler";
import useModalAuth from "../hooks/useModalAuth";
import useAuth from "../hooks/useAuth";
import { IHttpResponse } from "../helpers/pagination";
import { IAuthResponsePayload } from "../types/auth.type";
import SidebarMenu from "../components/SidebarMenu";
import useWindowSize from "../hooks/useWindowSize";
import { Menu as Ham, X, ChevronDown } from "react-feather";
import AppFooter from "./AppFooter";
import { LetsTalkModal } from "../components";
import SignInOrProfile from "../components/SignInOrProfile";
import ModalOrderCreated from "../components/ModalOrderCreated";
import axios from "axios";
import { IUser } from "../types/user.type";
import useGlobalSearch from "../hooks/useGlobalSearch";
import useGlobalConfig from "../hooks/useGlobalConfig";
import { theme } from "../assets/theme";
import useGlobalFilter from "../hooks/useGlobalFilter";
import { appVersion, thisYear } from "../helpers/constant";
import { GoogleLogin } from "react-google-login";
import FacebookLogin from "react-facebook-login";
// import jwt from "jsonwebtoken";
import { dataAbout } from "../constants";
import { EmailProps, initialEmail } from "../types/email.type";
import {
  FetchAllHomepageResponse,
  HomepageProps,
} from "../types/homepage.type";

const { Content } = Layout;

const headerMenus = [
  {
    key: "/",
    label: "Home",
  },
  {
    key: "/projects",
    label: "Projects",
  },
  {
    key: "/career",
    label: "Career",
  },
  {
    key: "/about-us",
    label: "About Us",
  },
];

const signInMenus = [
  {
    key: "orders",
    label: "My Orders",
  },
  {
    key: "profile",
    label: "My Profile",
  },
  {
    key: "sign-out",
    label: "Sign Out",
  },
];

type AdditionalProps = {
  showPageHeader?: boolean;
  pageHeaderTitle?: string;
  pageHeaderSubtitle?: string;
  showBanner?: boolean;
  hasSidebar?: boolean;
  isHomepage?: boolean;
  componentUnderSubtitle?: any;
  withoutContainer?: any;
  classLayout?: string;
};

type Aboutus = {
  aboutusId: string;
  email: string;
  emailCareer: string;
  contact: string;
  instagramLink: string;
  linkedinLink: string;
  companyDescription: string;
  visitUsDescription: string;
};

const AppLayout: FC<AdditionalProps> = ({
  children,
  showPageHeader,
  pageHeaderTitle,
  pageHeaderSubtitle,
  hasSidebar,
  isHomepage,
  componentUnderSubtitle,
  withoutContainer,
  classLayout,
}) => {
  const location = useLocation();
  const { aboutUs, init } = useGlobalConfig();
  const { globalKeyword, setGlobalKeyword } = useGlobalSearch();
  const history = useHistory();
  const { isLoggedIn, login, user, logout, getProfile } = useAuth();
  const { width, isMobile } = useWindowSize();
  const [isNavbarBackgroundWhite, setIsNavbarBackgroundWhite] = useState(
    isHomepage ? false : true
  );
  // const {
  //   isShowModalCart,
  //   setIsShowModalCart,
  //   carts,
  //   cartInit } = useCart();

  useEffect(() => {
    if (isLoggedIn) {
      getProfile();
    }
  }, [isLoggedIn]);

  useEffect(() => {
    if (!isHomepage) return;

    function isPageScrolled() {
      if (window.scrollY > 100) {
        if (isNavbarBackgroundWhite) return;
        setIsNavbarBackgroundWhite(true);
      } else {
        if (!isNavbarBackgroundWhite) return;
        setIsNavbarBackgroundWhite(false);
      }
    }

    window.addEventListener("scroll", isPageScrolled, { passive: true });

    return () => window.removeEventListener("scroll", isPageScrolled);
  }, [isHomepage, isNavbarBackgroundWhite]);

  const [isShowModalOrderCreated, setIsShowModalOrderCreated] = useState(false);

  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [emailData, setEmailData] = useState<EmailProps>(initialEmail);
  const [servicesData, setServicesData] = useState<Array<HomepageProps>>([]);
  const [selectedTags, setSelectedTags] = useState<Array<string>>([]);

  const [dataForOrderCreatedModal, setDataForOrderCreatedModal] = useState({
    email: "",
    locationName: "",
    locationAddress: "",
    locationContact: "",
  });

  const formRef =
    useRef<FormInstance<{ email: string; password: string }>>(null);

  const formRefRegister = useRef<
    FormInstance<{
      fullName: string;
      phoneNumber: string;
      email: string;
      password: string;
    }>
  >(null);

  const formRefPhone = useRef<
    FormInstance<{
      phone: string;
    }>
  >(null);

  const formRefOtp = useRef<
    FormInstance<{
      otp: string;
    }>
  >(null);

  const {
    isShowModalLogin,
    setIsShowModalLogin,
    isShowModalRegister,
    setIsShowModalRegister,
  } = useModalAuth();
  const [isLoadingLogin, setIsLoadingLogin] = useState(false);
  const [isLoadingRegister, setIsLoadingRegister] = useState(false);
  const [isAgreeSignUp, setIsAgreeSignUp] = useState(false);
  const [isShowDrawer, setIsShowDrawer] = useState(false);
  const { selectedLocation, setIsShowModalSelectLocation } = useGlobalFilter();

  const [isShowModalPhone, setIsShowModalPhone] = useState(false);
  const [isLoadingLoginByPhone, setIsLoadingLoginByPhone] = useState(false);

  const [isShowModalOtp, setIsShowModalOtp] = useState(false);
  const [isLoadingOtp, setIsLoadingOtp] = useState(false);

  const [sessionId, setSessionId] = useState("");

  const toggleDrawer = (value: boolean) => {
    setIsShowDrawer(value);
  };

  // scroll to top
  const refHeader = useRef<any>();
  useEffect(() => {
    if (!location.hash && refHeader && refHeader.current) {
      refHeader.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [location]);
  // scroll to top

  useEffect(() => {
    init();
  }, []);

  const onSubmitLogin = async (values: { email: string; password: string }) => {
    try {
      setIsLoadingLogin(true);
      const res = await axios.post<IHttpResponse<IAuthResponsePayload>>(
        "auth/signin",
        { email: values.email, password: values.password }
      );

      const resProfile = await axios.get<IHttpResponse<IUser>>("user/me", {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + res.data.payload.token,
        },
      });

      setIsLoadingLogin(false);

      login({
        token: res.data.payload.token,
        user: resProfile.data.payload,
      });

      message.success("Login successfully");

      setIsShowModalRegister(false);
      setIsShowModalLogin(false);
    } catch (err) {
      message.error("Login failed. " + getErrorMessage(err));
      setIsLoadingLogin(false);
    }
  };

  const onSubmitRegister = (values: {
    fullName: string;
    phoneNumber: string;
    email: string;
    password: string;
  }) => {
    setIsLoadingRegister(true);
    httpRequest
      .post("auth/register", {
        fullName: values.fullName,
        phoneNumber: values.phoneNumber,
        email: values.email,
        password: values.password,
      })
      .then((res) => {
        console.info("res", res.data);
        setIsLoadingRegister(false);

        message.success("Register successfully");

        setIsShowModalRegister(false);
        setIsShowModalLogin(true);
      })
      .catch((err) => {
        message.error(getErrorMessage(err));
        setIsLoadingRegister(false);
      });
  };

  const onSubmitLoginByPhone = (values: { phone: string }) => {
    setIsLoadingLoginByPhone(true);
    axios
      .post<IHttpResponse<{ sessionId: string }>>("auth/phone/send-otp", {
        phoneNumber: values.phone,
      })
      .then((res) => {
        setSessionId(res.data.payload.sessionId);

        setIsLoadingLoginByPhone(false);
        message.success("OTP Sent");
        setIsShowModalPhone(false);
        setIsShowModalOtp(true);
      })
      .catch((err) => {
        message.error(getErrorMessage(err));
        setIsLoadingLoginByPhone(false);

        // ByPass Error In Send OTP
        setIsShowModalPhone(false);
        setIsShowModalOtp(true);
        // PLEASE DELETE THIS WHEN WABA IS READY
      });
  };

  const onSubmitOtp = async (values: { otp: string }) => {
    setIsLoadingOtp(true);
    try {
      const submitRes = await axios.post<IHttpResponse<IAuthResponsePayload>>(
        "auth/phone/login/phone-otp",
        {
          otp: values.otp,
          sessionId: sessionId,
        }
      );

      const resProfile = await axios.get<IHttpResponse<IUser>>("user/me", {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + submitRes.data.payload.token,
        },
      });

      setIsLoadingLogin(false);

      login({
        token: submitRes.data.payload.token,
        user: resProfile.data.payload,
      });

      message.success("Login successfully");

      setIsShowModalOtp(false);
    } catch (err) {
      message.error("Submit OTP failed. " + getErrorMessage(err));
      setIsLoadingOtp(false);
    }
  };

  const handleClickOpenCart = async () => {
    // if (isLoggedIn) {
    //   setIsShowModalCart(!isShowModalCart)
    //   cartInit()
    // } else {
    //   setIsShowModalLogin(true)
    // }
  };

  useEffect(() => {
    const getServicesData = async () => {
      try {
        const res = await httpRequest.get<FetchAllHomepageResponse>(
          `/homepages`
        );

        setServicesData(res.data.payload.results);
      } catch (error) {
        message.error(getErrorMessage(error));
      }
    };
    getServicesData();
  }, []);

  const onClickSend = async () => {
    const selectedService = servicesData.filter((item) => {
      if (selectedTags?.includes(item.homepageId)) {
        return item;
      } else return null;
    });
    const newEmailData = {
      ...emailData,
      services: selectedService.map((item) => item.title),
    };

    await httpRequest.post("/contact-us", newEmailData);
    setIsModalVisible(false);
    setEmailData(initialEmail);
    message.success("Email Sent");
  };

  const handleSuccessGoogle = async (resp: any) => {
    try {
      setIsLoadingLogin(true);

      const resLogin = await httpRequest.post<
        IHttpResponse<IAuthResponsePayload>
      >("auth/google/signin", resp);

      const resProfile = await axios.get<IHttpResponse<IUser>>("user/me", {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + resLogin.data.payload.token,
        },
      });

      setIsLoadingLogin(false);

      login({
        token: resLogin.data.payload.token,
        user: resProfile.data.payload,
      });

      message.success("Login successfully");

      setIsShowModalRegister(false);
      setIsShowModalLogin(false);
    } catch (err) {
      message.error("Login failed. " + getErrorMessage(err));
      setIsLoadingLogin(false);
    }
  };

  const handleFailGoogle = (err: any) => {
    message.error("Login failed. " + getErrorMessage(err));
  };

  const handleSuccessFacebook = async (resp: any) => {
    try {
      setIsLoadingLogin(true);

      const resLogin = await httpRequest.post<
        IHttpResponse<IAuthResponsePayload>
      >("auth/facebook/signin", resp);

      const resProfile = await axios.get<IHttpResponse<IUser>>("user/me", {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + resLogin.data.payload.token,
        },
      });

      setIsLoadingLogin(false);

      login({
        token: resLogin.data.payload.token,
        user: resProfile.data.payload,
      });

      message.success("Login successfully");

      setIsShowModalRegister(false);
      setIsShowModalLogin(false);
    } catch (err) {
      message.error("Login failed. " + getErrorMessage(err));
      setIsLoadingLogin(false);
    }
  };

  const renderSignInModal = () => {
    return (
      <Modal
        title={<Title level={3}>Sign In</Title>}
        visible={isShowModalLogin}
        footer={null}
        onCancel={() => {
          setIsShowModalLogin(false);
        }}
        width={isMobile ? "90%" : 400}
      >
        <Form
          ref={formRef}
          layout="vertical"
          name="basic"
          initialValues={{
            email: "",
            password: "",
          }}
          onFinish={onSubmitLogin}
        >
          <Form.Item
            name="email"
            label="Email address"
            rules={generateFormRules("Email address", ["required", "email"])}
          >
            <Input
              size="large"
              type="email"
              prefix={<MailOutlined />}
              placeholder="Email address"
            />
          </Form.Item>

          <Form.Item name="password" label="Password">
            <Input.Password
              size="large"
              prefix={<LockOutlined />}
              placeholder="Password"
            />
          </Form.Item>

          <Text
            style={{
              display: "block",
              textAlign: "right",
              paddingBottom: 20,
            }}
          >
            <Link
              style={{
                textDecoration: "underline",
              }}
              onClick={() => {
                setIsShowModalLogin(false);
              }}
              to="/forgot-password"
            >
              Forgot Password?
            </Link>
          </Text>

          <Form.Item>
            <AppButton
              loading={isLoadingLogin}
              size="large"
              type="primary"
              htmlType="submit"
              style={{ width: "100%" }}
            >
              Sign In
            </AppButton>
          </Form.Item>

          <AppButton
            loading={isLoadingLogin}
            size="large"
            type="primary"
            htmlType="submit"
            style={{
              width: "100%",
              backgroundColor: "green",
              marginBottom: "24px",
            }}
            onClick={() => {
              setIsShowModalLogin(false);
              setIsShowModalPhone(true);
            }}
          >
            WhatsApp
          </AppButton>

          <GoogleLogin
            clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
            buttonText="Login by Google"
            onSuccess={handleSuccessGoogle}
            onFailure={handleFailGoogle}
            render={(props: any) => (
              <Button
                onClick={props.onClick}
                disabled={props.disabled}
                style={{
                  borderRadius: 5,
                  width: "100%",
                  marginBottom: "24px",
                }}
              >
                Login with Google
              </Button>
            )}
            // cookiePolicy={'single_host_origin'}
          />

          <FacebookLogin
            appId="1953881364795280"
            autoLoad={false}
            fields="name,email"
            callback={handleSuccessFacebook}
            buttonStyle={{
              width: "100%",
              height: "42px",
              marginBottom: "24px",
              padding: 0,
            }}
          />

          <Text style={{ display: "block", textAlign: "center" }}>
            Didn't have an account?{" "}
            <Link
              style={{
                color: "#0B998E",
                fontWeight: "bold",
                textDecoration: "none",
              }}
              onClick={() => {
                setIsShowModalLogin(false);
                setIsShowModalRegister(true);
              }}
              to="#"
            >
              Sign Up
            </Link>
          </Text>
        </Form>
        <Row justify="center" style={{ paddingTop: "10px" }}>
          <Text type="secondary" style={{ fontSize: "10px" }}>
            v{appVersion} · © {thisYear}. qbit
          </Text>
        </Row>
      </Modal>
    );
  };

  const renderSignUpModal = () => {
    return (
      <Modal
        title={<Title level={3}>Sign Up</Title>}
        visible={isShowModalRegister}
        footer={null}
        onCancel={() => {
          setIsShowModalRegister(false);
        }}
        width={isMobile ? "90%" : 500}
      >
        <Form
          ref={formRefRegister}
          layout="vertical"
          name="basic"
          initialValues={{
            fullName: "",
            phoneNumber: "",
            email: "",
            password: "",
          }}
          onFinish={onSubmitRegister}
        >
          <Form.Item
            name="fullName"
            label="Full Name"
            rules={generateFormRules("Full Name", ["required"])}
          >
            <Input
              size="large"
              type="fullName"
              prefix={<UserOutlined />}
              placeholder="Full Name"
            />
          </Form.Item>
          <Form.Item
            name="phoneNumber"
            label="Phone Number"
            rules={generateFormRules("Phone Number", [
              "required",
              "phoneNumber",
            ])}
          >
            <Input
              size="large"
              type="phoneNumber"
              prefix={<PhoneOutlined />}
              placeholder="Phone Number"
            />
          </Form.Item>
          <Form.Item
            name="email"
            label="Email address"
            rules={generateFormRules("Email address", ["required", "email"])}
          >
            <Input
              size="large"
              type="email"
              prefix={<MailOutlined />}
              placeholder="Email address"
            />
          </Form.Item>

          <Form.Item
            name="password"
            label="Password"
            rules={generateFormRules("Password", ["required", "password"])}
          >
            <Input.Password
              size="large"
              prefix={<LockOutlined />}
              placeholder="Password"
            />
          </Form.Item>

          <Form.Item>
            <Checkbox
              onChange={() => setIsAgreeSignUp(!isAgreeSignUp)}
              style={{ fontSize: 12 }}
            >
              I agree to qbit{" "}
              <Link
                target="_blank"
                style={{
                  color: "#0B998E",
                  fontWeight: "bold",
                  textDecoration: "none",
                }}
                to="/terms-and-conditions"
              >
                Terms and conditions
              </Link>{" "}
              &{" "}
              <Link
                target="_blank"
                style={{
                  color: "#0B998E",
                  fontWeight: "bold",
                  textDecoration: "none",
                }}
                to="/privacy-policy"
              >
                Privacy Policy
              </Link>
            </Checkbox>
          </Form.Item>

          <Form.Item>
            <AppButton
              disabled={!isAgreeSignUp}
              loading={isLoadingRegister}
              size="large"
              type="primary"
              htmlType="submit"
              style={{ width: "100%" }}
              // onClick={() => formRefRegister.current?.submit()}
            >
              Sign Up
            </AppButton>
          </Form.Item>

          <Text style={{ display: "block", textAlign: "center" }}>
            Already have an account?{" "}
            <Link
              style={{
                color: "#0B998E",
                fontWeight: "bold",
                textDecoration: "none",
              }}
              onClick={() => {
                setIsShowModalRegister(false);
                setIsShowModalLogin(true);
              }}
              to="#"
            >
              Sign In
            </Link>
          </Text>
        </Form>
      </Modal>
    );
  };

  const renderPhoneModal = () => {
    return (
      <Modal
        title={<Title level={3}>Login with Whatsapp</Title>}
        visible={isShowModalPhone}
        footer={null}
        onCancel={() => {
          setIsShowModalPhone(false);
        }}
        width={isMobile ? "90%" : 500}
      >
        <Form
          ref={formRefPhone}
          layout="vertical"
          name="basic"
          initialValues={{
            phone: "",
          }}
          onFinish={onSubmitLoginByPhone}
        >
          <Form.Item
            name="phone"
            label="Phone Number"
            rules={generateFormRules("Phone Number", [
              "required",
              "phoneNumber",
            ])}
          >
            <Input
              size="large"
              type="number"
              prefix={<UserOutlined />}
              placeholder="Phone Number"
            />
          </Form.Item>

          <Form.Item>
            <AppButton
              loading={isLoadingLoginByPhone}
              size="large"
              type="primary"
              htmlType="submit"
              style={{ width: "100%" }}
            >
              Send OTP
            </AppButton>
          </Form.Item>
        </Form>
      </Modal>
    );
  };

  const renderOtpModal = () => {
    return (
      <Modal
        title={<Title level={3}>Enter OTP Sent to Your Whatsapp</Title>}
        visible={isShowModalOtp}
        footer={null}
        onCancel={() => {
          setIsShowModalOtp(false);
        }}
        width={isMobile ? "90%" : 500}
      >
        <Form
          ref={formRefOtp}
          layout="vertical"
          name="basic"
          initialValues={{
            otp: "",
          }}
          onFinish={onSubmitOtp}
        >
          <Form.Item
            name="otp"
            label="OTP Sent"
            rules={generateFormRules("OTP", ["required"])}
          >
            <Input
              size="large"
              type="name"
              prefix={<UserOutlined />}
              placeholder="OTP"
            />
          </Form.Item>

          <Form.Item>
            <AppButton
              loading={isLoadingOtp}
              size="large"
              type="primary"
              htmlType="submit"
              style={{ width: "100%" }}
            >
              Submit
            </AppButton>
          </Form.Item>
        </Form>
      </Modal>
    );
  };

  return (
    <Layout className={"layout " + (isHomepage ? "bg-header-homepage" : "")}>
      <header ref={refHeader}>
        <Drawer
          closable={false}
          placement="left"
          onClose={() => toggleDrawer(false)}
          visible={isShowDrawer}
        >
          <Link
            className="drawer-nav"
            to="#"
            onClick={() => {
              toggleDrawer(false);
            }}
          >
            <img
              alt="logo-app"
              src={dataAbout?.logo || "/images/logo-app.svg"}
              height={40}
              style={{ maxHeight: 45, maxWidth: 200 }}
            />
            <X onClick={() => toggleDrawer(false)} />
          </Link>
          <div className="drawer-menu">
            {/* <Link to="/">Home</Link> */}
            {headerMenus.map((item, index) => (
              <Link key={index} to={item.key}>
                {item.label}
              </Link>
            ))}
          </div>
          <div className="lets-talk-drawer">
            <p>Let us know your project details!</p>
            <button className="btn" onClick={() => setIsModalVisible(true)}>
              <span>Lets talk</span>
            </button>
          </div>

          <Divider />

          {/* TODO: Get auth status */}

          {/* <div className="user-info-container">
            {isLoggedIn ? (
              <div>
                <div className="user-info-wrapper">
                  <Avatar
                    style={{
                      backgroundColor: "#e5e9ec",
                      verticalAlign: "middle",
                    }}
                    size="large"
                  >
                    {generateInitialFromName(user.name)}
                  </Avatar>
                  <div className="user-info">
                    <span>Welcome back,</span>
                    <h4>{user.name}</h4>
                  </div>
                </div>
                <div className="user-menu">
                  {signInMenus.map((item) =>
                    item.key === "sign-out" ? (
                      <Link
                        to="#"
                        key={item.key}
                        onClick={() => {
                          Modal.confirm({
                            title: "Confirmation",
                            content: "Are you sure want to logout?",
                            onOk: () => {
                              logout();
                              history.replace({ pathname: "/" });
                            },
                            okText: `Yes, I'm sure`,
                            okType: "primary",
                            width: isMobile ? "90%" : undefined,
                          });
                        }}
                      >
                        {item.label}
                      </Link>
                    ) : (
                      <Link key={item.key} to={item.key}>
                        {item.label}
                      </Link>
                    )
                  )}
                </div>

                <button
                  className="btn"
                  style={{
                    width: "100%",
                    marginTop: 20,
                    marginBottom: 20,
                  }}
                  onClick={() => history.push("/menu")}
                >
                  <MenuIcon color="#fff" />
                  <span
                    style={{
                      flex: 1,
                      justifyContent: "flex-start",
                      textAlign: "left",
                    }}
                  >
                    Order Now
                  </span>
                  <ChevronRight color="#fff" />
                </button>
              </div>
            ) : (
              <div className="user-info-signin-wrapper">
                <p>Didn’t have account?</p>
                <p>Create your account now</p>
                <button
                  onClick={() => setIsShowModalRegister(true)}
                  className="btn"
                  style={{ width: "100%" }}
                >
                  Create account
                </button>
                <p>or</p>
                <button
                  onClick={() => setIsShowModalLogin(true)}
                  className="btn"
                  style={{ width: "100%" }}
                >
                  Sign In
                </button>
              </div>
            )}

            <div className="menu-footer-container">
              <div className="footer-social-media-wrap">
                <a href={aboutUs.instagramLink}>
                  <img alt="instagram" src="images/instagram.svg" />
                </a>
                <a href={aboutUs.twitterLink}>
                  <img alt="twitter" src="images/twitter.svg" />
                </a>
                <a href={aboutUs.facebookLink}>
                  <img alt="facebook" src="images/facebook.svg" />
                </a>
              </div>
            </div>
          </div> */}
        </Drawer>

        <div
          className={
            "header-homepage-wrapper" +
            (isNavbarBackgroundWhite ? " header-homepage-white" : "")
          }
        >
          <div
            className="header-homepage"
            style={isMobile ? { height: 60 } : undefined}
          >
            {width <= 1199 ? (
              <>
                <button
                  onClick={() => toggleDrawer(true)}
                  className="btn-sidebar"
                >
                  <Ham color={!isNavbarBackgroundWhite ? "#ffffff" : "#000000"} />
                </button>

                {isMobile && location.pathname === "/menu" ? (
                  <Row
                    style={isMobile ? { width: "90%" } : undefined}
                    onClick={() => {
                      setIsShowModalSelectLocation(true);
                    }}
                  >
                    {selectedLocation.locationId ? (
                      <Col xs={24}>
                        <Row
                          align="middle"
                          justify="space-between"
                          style={{
                            padding: "5px 10px",
                          }}
                        >
                          <Col xs={20}>
                            <Row align="middle">
                              <Col xs={7}>
                                <Text
                                  style={{
                                    background: theme.colors.primary,
                                    padding: "5px 8px",
                                    fontSize: 10,
                                    borderRadius: 20,
                                    color: "#FFFFFF",
                                  }}
                                >
                                  Pickup
                                </Text>
                              </Col>
                              <Col xs={17}>
                                <Row>
                                  <Text
                                    style={{ color: "darkgrey", fontSize: 10 }}
                                  >
                                    Pickup Location
                                  </Text>
                                </Row>
                                <Row>
                                  <Text ellipsis style={{ fontSize: 11 }}>
                                    {selectedLocation?.locationAddress}
                                  </Text>
                                </Row>
                              </Col>
                            </Row>
                          </Col>
                          <ChevronDown />
                        </Row>
                      </Col>
                    ) : (
                      <Col xs={24}>
                        <Row
                          justify="space-between"
                          style={{
                            padding: "5px 10px",
                            border: "1px lightgrey solid",
                            borderRadius: 30,
                          }}
                        >
                          <Text>Choose Pickup Location</Text>
                          <ChevronDown />
                        </Row>
                      </Col>
                    )}
                  </Row>
                ) : (
                  <Link className="logo-wrapper" to="/">
                    <img alt="logo-app" src={dataAbout?.logo} height={40} />
                  </Link>
                )}

                {/* {isMobile &&
              (location.pathname === "/menu" ||
                location.pathname.includes("/profile") ||
                location.pathname === "/orders") ? (
                <div style={{ marginLeft: 10 }}>
                  {isLoggedIn ? (
                    <UserAvatar />
                  ) : (
                    <AppButton
                      onClick={() => setIsShowModalLogin(true)}
                      loading={isLoadingLogin}
                      size="small"
                      type="primary"
                      style={{ borderRadius: 20 }}
                    >
                      Sign In
                    </AppButton>
                  )}
                </div>
              ) : location.pathname === "/menu" ? (
                <button className="btn" onClick={handleClickOpenCart}>
                  <ShoppingCartOutlined
                    size={18}
                    color="#fff"
                    style={{ marginRight: 10 }}
                  />
                  <span>Cart · {carts.length}</span>
                </button>
              ) : (
                <Link to="/menu" className="btn-menu">
                  <MenuIcon color="#FFF" />
                </Link>
              )} */}
              </>
            ) : (
              <>
                <Link className="logo-wrapper" to="/">
                  <img alt="logo-app" src={dataAbout?.logo} />
                </Link>

                {location.pathname.includes("/menu") ? (
                  <div
                    className="homepage-menu"
                    style={{ gap: 20, width: "78%" }}
                  >
                    <Form
                      name="form"
                      layout="vertical"
                      initialValues={{
                        search: "",
                      }}
                      autoComplete="off"
                      style={{ flex: 1 }}
                    >
                      <Form.Item
                        name="search"
                        rules={[]}
                        style={{ marginBottom: 0 }}
                      >
                        <Input
                          style={{ borderRadius: 50 }}
                          size="large"
                          placeholder="What are you looking for?"
                          prefix={<SearchOutlined />}
                          onChange={(e) => setGlobalKeyword(e.target.value)}
                          value={globalKeyword}
                        />
                      </Form.Item>
                    </Form>

                    <button className="btn" onClick={handleClickOpenCart}>
                      <ShoppingCartOutlined
                        size={18}
                        color="#fff"
                        style={{ marginRight: 10 }}
                      />
                      {/* <span>Cart · {carts.length}</span> */}
                    </button>

                    <SignInOrProfile />
                  </div>
                ) : (
                  <>
                    <div className="homepage-menu">
                      {headerMenus.map((menu, index) => (
                        <Link
                          to={menu.key}
                          key={menu.key}
                          style={{
                            marginLeft: index > 0 ? 45 : undefined,
                            color: isHomepage && !isNavbarBackgroundWhite ? "#ffffff" : "#000000",
                            borderBottom:
                              history.location.pathname === menu.key
                                ? "solid #0B998E 3px"
                                : "none",
                          }}
                        >
                          {menu.label}
                        </Link>
                      ))}
                    </div>
                    <button
                      className="btn"
                      onClick={() => setIsModalVisible(true)}
                    >
                      {/* <MenuIcon size={18} color="#fff" /> */}
                      <span style={{ marginLeft: 5 }}>Lets talk</span>
                    </button>
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </header>

      {showPageHeader && !!pageHeaderTitle && (
        <PageHeader
          title={pageHeaderTitle}
          subtitle={pageHeaderSubtitle}
          componentUnderSubtitle={componentUnderSubtitle}
        />
      )}

      <Row
        style={
          hasSidebar && !isMobile
            ? { paddingLeft: 100, paddingRight: 100, background: "#F9FAFA" }
            : undefined
        }
      >
        {hasSidebar && !isMobile && <SidebarMenu />}

        <Content
          className="layout-content"
          style={
            hasSidebar && !isMobile
              ? {
                  padding: "0 20px",
                  width: "65%",
                  borderRadius: 10,
                  minHeight: 500,
                  background: "#FFFFFF",
                }
              : hasSidebar && isMobile
              ? {
                  width: "100%",
                  borderRadius: 10,
                  minHeight: 500,
                  background: "#FFFFFF",
                }
              : {
                  width: "100%",
                  minHeight: 500,
                  background: "#FFFFFF",
                }
          }
        >
          {withoutContainer ? (
            children
          ) : (
            <div
              className={`site-layout-content ${classLayout || ""}`}
              style={hasSidebar && isMobile ? { paddingTop: 0 } : undefined}
            >
              {children}
            </div>
          )}
        </Content>
      </Row>

      {renderSignInModal()}

      {renderSignUpModal()}

      {renderPhoneModal()}

      {renderOtpModal()}

      {/* <ModalCart
        onCheckoutSuccess={(item) => {
          setDataForOrderCreatedModal(item);
          setIsShowModalOrderCreated(true);
        }}
      /> */}

      <ModalOrderCreated
        {...dataForOrderCreatedModal}
        visible={isShowModalOrderCreated}
        onClose={() => {
          setIsShowModalOrderCreated(false);
        }}
      />

      <LetsTalkModal
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        initialEmail={initialEmail}
        setEmailData={setEmailData}
        onClickSend={onClickSend}
        servicesData={servicesData}
        emailData={emailData}
        selectedTags={selectedTags}
        setSelectedTags={setSelectedTags}
      />

      <AppFooter />
    </Layout>
  );
};

export default AppLayout;
