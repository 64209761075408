import { createGlobalState } from 'react-hooks-global-state';
import { httpRequest } from '../helpers/api';
import { IVariant } from '../types/menu.type';
import { message } from 'antd';
import { getErrorMessage } from '../helpers/errorHandler';
import { IHttpResponse, IPayloadPagination } from '../helpers/pagination';
import { ILocation } from '../types/location.type';

export type CartItem = {
  cartId?: string;
  productId: string;
  productVariantId?: string;
  quantity: number;
  buyerId: string;

  productDetail?: {
    name: string;
    variant: IVariant;
    variants: IVariant[];
  };
  meta?: {
    pickupFromLocationId: string;
    pickupFromLocationDetail?: ILocation;
    pickupScheduledAt: string;
    pickupTime: {
      startAt?: string;
      endAt?: string;
    };
  };
};

const initialState: {
  isLoading: boolean;
  carts: CartItem[];
  isShowModalCart: boolean;
} = { 
  isLoading: false, 
  carts: [], 
  isShowModalCart: false 
};
const { useGlobalState } = createGlobalState(initialState);

export default function useCart() {
  const [isLoading, setIsLoading] = useGlobalState('isLoading');
  const [carts, setCarts] = useGlobalState('carts');
  const [isShowModalCart, setIsShowModalCart] =
    useGlobalState('isShowModalCart');

  const addToCart = async (value: CartItem, how: 'add' | 'replace' = 'add') => {
    setIsLoading(true);
    try {
      let tmpCart = [...carts]
      const variantCart = tmpCart.find(
        (item) =>
          item.productVariantId === value.productVariantId &&
          item.meta?.pickupFromLocationId ===
            value.meta?.pickupFromLocationId &&
          item.meta?.pickupScheduledAt === value.meta?.pickupScheduledAt &&
          item.meta?.pickupTime.startAt === value.meta?.pickupTime.startAt &&
          item.meta?.pickupTime.endAt === value.meta?.pickupTime.endAt
      );

      if (variantCart) {
        tmpCart.forEach(item => {
          if (
            item.productVariantId === value.productVariantId 
            && item.meta?.pickupFromLocationId === value.meta?.pickupFromLocationId 
            && item.meta?.pickupScheduledAt === value.meta?.pickupScheduledAt 
            && item.meta?.pickupTime.startAt === value.meta?.pickupTime.startAt &&
            item.meta?.pickupTime.endAt === value.meta?.pickupTime.endAt
          ) {
            item.quantity = how === 'replace' ? value.quantity : item.quantity + value.quantity
          }
        })

        if (how === 'replace' && variantCart.quantity === 0) {
          tmpCart = tmpCart.filter(
            (item) =>
              !(
                item.productVariantId === variantCart.productVariantId &&
                item.meta?.pickupFromLocationId ===
                  variantCart.meta?.pickupFromLocationId &&
                item.meta?.pickupScheduledAt ===
                  variantCart.meta?.pickupScheduledAt &&
                item.meta?.pickupTime.startAt ===
                  variantCart.meta?.pickupTime.startAt &&
                item.meta?.pickupTime.endAt ===
                  variantCart.meta?.pickupTime.endAt
              )
          );
        }
        await httpRequest.post('/carts', variantCart)
      }
      else {
        tmpCart.push(value)
        await httpRequest.post('/carts', value)
      }

      cartInit()
      setIsLoading(false)

      message.info('Food Added to Cart');
    } catch (err) {
      setIsLoading(false)
      message.error('Failed to update cart. ' + getErrorMessage(err))
    }
  }

  const addMultipleToCart = async (values: CartItem[], how: 'add' | 'replace' = 'add', callbackOnSuccess: Function) => {
    setIsLoading(true)
    try {
      const tmpCart = [...carts];

      let promises = []
      for (const value of values) {
        const variantCart = tmpCart.find(
          (item) =>
            item.productVariantId === value.productVariantId &&
            item.meta?.pickupFromLocationId ===
              value.meta?.pickupFromLocationId &&
            item.meta?.pickupScheduledAt === value.meta?.pickupScheduledAt &&
            item.meta?.pickupTime.startAt === value.meta?.pickupTime.startAt &&
            item.meta?.pickupTime.endAt === value.meta?.pickupTime.endAt
        );

        if (variantCart) {
          tmpCart.forEach((item) => {
            if (
              item.productVariantId === value.productVariantId &&
              item.meta?.pickupFromLocationId ===
                value.meta?.pickupFromLocationId &&
              item.meta?.pickupScheduledAt === value.meta?.pickupScheduledAt &&
              item.meta?.pickupTime.startAt ===
                value.meta?.pickupTime.startAt &&
              item.meta?.pickupTime.endAt === value.meta?.pickupTime.endAt
            ) {
              item.quantity =
                how === 'replace'
                  ? value.quantity
                  : item.quantity + value.quantity;
            }
          });

          promises.push(httpRequest.post('/carts', variantCart))
        } else {
          tmpCart.push(value);
          promises.push(httpRequest.post('/carts', value))
        }
      }

      await Promise.all(promises);
      if (how === 'replace') {
        message.success('Success update product to cart')
      } else {
        message.success('Success add product to cart')
      }
      setCarts(tmpCart);

      if (callbackOnSuccess) {
        callbackOnSuccess();
      }
      setIsLoading(false)

      // message.info('Food Added to Cart');
    } catch (err) {
      setIsLoading(false)
      message.error('Failed to update cart. ' + getErrorMessage(err));
    }
  };

  const cartInit = async () => {
    try {
      const resCart = await httpRequest.get<IHttpResponse<IPayloadPagination<CartItem>>>(`/carts`)
      setCarts(resCart.data.payload.results)
    } catch(err) {
      message.error('Failed to get cart data. ' + getErrorMessage(err))
    }
  }

  const clearCart = async () => {
    try {
      await httpRequest.delete<IHttpResponse<IPayloadPagination<CartItem>>>(`/carts`)
      setCarts([])
    } catch(err) {
      message.error('Failed to clear cart. ', + getErrorMessage(err))
    }
  }

  const removeProductVariantFromCart = async (productId: string, productVariantId: string) => {
    const newCart = carts.filter((item) => item.productVariantId !== productVariantId);
    await httpRequest.delete(
      `/carts/${productId}/${productVariantId}`
    );
    setCarts(newCart);
  };

  const removeProductVariantByPickupScheduleFromCart = async (
    productId: string,
    productVariantId: string,
    pickupScheduledAt: string,
    pickupTimeStart: string,
    pickupTimeEnd: string,
  ) => {
    await httpRequest.delete(`/carts/${productId}/${productVariantId}/${pickupScheduledAt}/${pickupTimeStart}/${pickupTimeEnd}`);
    cartInit();
  };

  const updateVariant = async (cartId: string, newVariantId: string) => {
    setIsLoading(true)
    try {
      await httpRequest.patch('/carts/' + cartId + '/variant', {
        productVariantId: newVariantId
      })
      setIsLoading(false)
      cartInit()
    } catch(err) {
      message.error('Failed update variant. ' + getErrorMessage(err))
    }
  }

  return {
    isLoading,
    carts,
    locationIdInCart:
      carts.length > 0 ? carts[0].meta?.pickupFromLocationId : undefined,
    addToCart,
    addMultipleToCart,
    cartInit,
    clearCart,
    removeProductVariantFromCart,
    removeProductVariantByPickupScheduleFromCart,
    updateVariant,

    isShowModalCart,
    setIsShowModalCart,
  };
}
