import React, {useEffect, useState} from 'react';
import { httpRequest } from '../helpers/api';
import { IHttpResponse } from '../helpers/pagination';
import AppLayout from '../layout/AppLayout';
import { IPrivacyPolicy } from '../types/privacyPolicy.type';
import { message } from 'antd';
import { getErrorMessage } from '../helpers/errorHandler';

const PrivacyPolicy: React.FC = () => {
  const [privacyPolicy, setPrivacyPolicy] = useState<IPrivacyPolicy>()

  useEffect(() => {
    init();
  }, [])

  const init = async () => {
    try {
      const res = await httpRequest.get<IHttpResponse<IPrivacyPolicy>>(
        `privacy-policy`
      );

      setPrivacyPolicy(res.data.payload)
    } catch(err) {
      message.error(getErrorMessage(err))
    }
  }

  return (
    <AppLayout showPageHeader={true} pageHeaderTitle="Privacy Policy" >
      {privacyPolicy ?
        <div dangerouslySetInnerHTML={{__html: privacyPolicy.privacypolicyText}} />
        : false}
    </AppLayout>
  );
};

export default PrivacyPolicy;
