import AppLayout from "../layout/AppLayout";
import {
  IntroBanner,
  Brief,
  Values,
  Goals,
  WeLove,
  Teams,
} from "../components";

export default function AboutUs() {
  return (
    <AppLayout withoutContainer>
      <IntroBanner
        title="About Us"
        desc="We develop product and project with unique and creative solutions that meet the client's expectations not only by realizing the client's business objectives, but also by considering the effective approach in IT solution."
      />
      <Brief />
      <Values />
      <Goals />
      <Teams />
      <WeLove />
    </AppLayout>
  );
}
