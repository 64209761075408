import { useLocation } from 'react-router-dom'

export default function useLocationQuery() {
  const location = useLocation()
  const queryString = location.search.substring(1)
  const query: {[key: string]: string} = {}
  const objQuery = queryString.split('&').forEach(item => {
      // query[item.split('=')[0]] = item.split('=')[1].replace('%20', ' ')
      query[item.split('=')[0]] = decodeURIComponent(item.split('=')[1])
      
  })

  return {
    ...query
  }
}