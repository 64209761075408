import { useState, createRef, useRef, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { ItemTeams } from "./";
interface IProps {
  data?: any;
}

const heightSectionTeam = 1500;
export default function SliderTeams({ data }: IProps) {
  let refContainerSlider: any = useRef();
  const [slider1, setSlider1] = useState<any>(null);
  const [slider2, setSlider2] = useState<any>(null);
  let refs = data?.reduce((x: any, item: any) => {
    x[`${item?.name?.split(" ")?.join("")}_${item?.id}`] = createRef();
    return x;
  }, {});

  const settings = {
    dots: true,
    dotsClass: "slick-dots slick-thumb pagination-container",
    infinite: true,
    speed: 500,
    focusOnSelect: true,
    customPaging: function (i: any) {
      return (
        <div
          ref={
            refs[`${data?.[i]?.name?.split(" ")?.join("")}_${data?.[i]?.id}`]
          }
        />
      );
    },
  };

  const detectScroll = () => {
    const offsetWindow = window.pageYOffset;
    const offsetTop =
      refContainerSlider?.current?.offsetTop +
      refContainerSlider?.current?.offsetParent?.offsetTop;
    let index = Math.floor((offsetWindow - offsetTop) / heightSectionTeam);
    console.log("INDEX", index);
    if (index >= 0) {
      refs[
        `${data?.[index]?.name?.split(" ")?.join("")}_${data?.[index]?.id}`
      ]?.current?.click();
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", detectScroll);
    return () => window.removeEventListener("scroll", detectScroll);
  });

  return (
    <div
      ref={refContainerSlider}
      className="slider-teams"
      style={{ height: data?.length * heightSectionTeam }}
    >
      <div className="slider-container">
        <Slider
          {...settings}
          asNavFor={slider2}
          ref={(slider: any) => setSlider1(slider)}
          centerMode
          centerPadding="60px"
          slidesToShow={3}
          className="ava-teams"
        >
          {data?.map((item: any, index: number) => (
            <div key={index} className="img-container">
              <img src={item?.img} alt="img-teams" />
            </div>
          ))}
        </Slider>
        <Slider
          {...settings}
          asNavFor={slider1}
          ref={(slider: any) => setSlider2(slider)}
          className="content-teams"
        >
          {data?.map((item: any, index: number) => (
            <ItemTeams key={index} item={item} />
          ))}
        </Slider>
      </div>
    </div>
  );
}
