const APP_AUTH_TOKEN = 'APP_AUTH_TOKEN_' + process.env.REACT_APP_ID;
const APP_AUTH_PROFILE = 'APP_AUTH_PROFILE_' + process.env.REACT_APP_ID;

export async function saveToken(token: string) {
  return localStorage.setItem(APP_AUTH_TOKEN, token);
}

export async function getToken() {
  return localStorage.getItem(APP_AUTH_TOKEN);
}

export async function removeToken() {
  localStorage.removeItem(APP_AUTH_TOKEN);
}
