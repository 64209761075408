import { Col, Divider, Modal, Row } from "antd";
import AppButton from "./AppButton";
import Text from "antd/lib/typography/Text";
import Title from "antd/lib/typography/Title";
import IconTudung from "./icons/iconTudung";
import { useHistory } from "react-router-dom";

type Props = {
  email: string;
  locationName: string;
  locationAddress: string;
  locationContact: string;

  visible: boolean;
  onClose: () => void;
};
export default function ModalOrderCreated(props: Props) {
  const history = useHistory();
  return (
    <Modal
      title={
        <Title style={{ marginBottom: 0 }} level={3}>
          Order Created
        </Title>
      }
      visible={props.visible}
      footer={null}
      onCancel={() => {
        props.onClose();
      }}
    >
      <Row justify="center">
        <Col
          style={{
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              background: "#0B998E",
              width: 60,
              height: 60,
              borderRadius: 10,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginBottom: 20,
            }}
          >
            <IconTudung size={40} />
          </div>
          <Text>
            We will send payment information via email to{" "}
            <a
              href={"mailto:" + props.email}
              style={{ color: "#0B998E" }}
              target="_blank"
              rel="noreferrer"
            >
              “{props.email}”
            </a>
            , please check your email to process the payment.
          </Text>
        </Col>
      </Row>

      <Divider />

      <Row
        style={{
          display: "block",
          width: "100%",
          paddingTop: 15,
          paddingBottom: 15,
        }}
      >
        <Col>
          <div style={{ marginBottom: 5 }}>
            For further question please contact us at
          </div>
          <div>
            <Text style={{ fontWeight: "bold" }}>{props.locationName}</Text>
          </div>
          <div>
            <Text>{props.locationAddress}</Text>
          </div>
          <div>
            <Text>{props.locationContact}</Text>
          </div>
        </Col>
      </Row>

      <AppButton
        onClick={() => {
          history.push("/orders");
        }}
        block
        size="large"
        type="primary"
        htmlType="submit"
        style={{ borderRadius: 20 }}
      >
        Check My Order List
      </AppButton>
    </Modal>
  );
}
